import {Component, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ConfigGeneratorService} from '../service/config-generator.service';
import {Pack} from '../../../types';
import {configGeneratorValidators} from './config-generator.validators';
import {PasswordGeneratorService} from '../service/password-generator.service';
import {QueryStringService} from '../service/query-string.service';
import {ClipboardService} from '../service/clipboard.service';
import {factory, KeyPair, PUBLIC_KEY} from '@ngmedax/common-license';
import {ToastService} from '@ngmedax/toast';


@Component({
  selector: 'app-config-generator-crud',
  templateUrl: './config-generator-crud.component.html',
  styleUrls: ['./config-generator-crud.component.css']
})
export class ConfigGeneratorCrudComponent  {

  /**
   * Default locale
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * config generator form
   * @type {FormGroup}
   */
  public form: FormGroup;

  /**
   * Template pack
   * @type {Pack}
   */
  public pack: Pack;

  /**
   * Unmodified copy of template pack
   * @type {Pack}
   */
  public originalPack: Pack;

  /**
   * Name of all template pack variables
   * @type {string[]}
   */
  public varNames: string[] = [];

  /**
   * Available versions
   * @type {string[]}
   */
  public versions: string[] = [];

  /**
   * Currently selected version
   * @type {string}
   */
  public selectedVersion: string = '';

  /**
   * Selected fill out mode
   * @type {string}
   */
  public selectedMode = '';

  /**
   * Template url
   * @type {string}
   */
  public templateUrl;

  /**
   * Should we display in full screen?
   * @type {boolean}
   */
  public displayFullScreen = false;

  /**
   * Do we have clipboard api available?
   * @type {boolean}
   */
  public hasClipboardApi = false;

  /**
   * Variables from previous config
   * @type {{[prop: string]: any}}
   */
  private previousVariables: {[prop: string]: any} = {};

  /**
   * Should we copy template to clipboard?
   * @type {boolean}
   */
  private clipboardMode = false;

  /**
   * License decoder
   * @type {LicenseDecoder}
   */
  private licenseDecoder = factory.getLicenseDecoder();

  /**
   * Injects dependencies and initializes available versions
   */
  public constructor(
    private formBuilder: FormBuilder,
    private clipboard: ClipboardService,
    private queryString: QueryStringService,
    private cfgGenerator: ConfigGeneratorService,
    private pwGenerator: PasswordGeneratorService,
    @Optional() private toast: ToastService,
  ) {
    this.displayFullScreen = !!location.href.match(/\/full-screen/);
    <any>this.loadVersions();

    this.clipboardMode = !!this.queryString.getParam('clipboardMode');
    this.hasClipboardApi = !!(<any>window.navigator).clipboard;

    (async () => {
      const configJson = this.queryString.getParam('config');
      if (!configJson) {return;}

      try {
        const config = JSON.parse(configJson);
        if (!config) {return;}

        const sure = await confirm('Es wurden Variablen übergeben. Möchtest du diese übernehmen?');
        if (!sure) {return;}
        
        this.previousVariables = config;

        if (config.CHANNEL && this.cfgGenerator.getChannel() !== config.CHANNEL) {
          this.cfgGenerator.setChannel(config.CHANNEL);
          await this.loadVersions();
        }

        this.pack && this.renderPreviousVariables();
        alert('Die Variablen wurden erfolgreich übernommen.');
      } catch (error) {
        console.error(error);
        alert('Beim Laden der Variablen ist ein Fehler aufgetreten.');
      }
    })();
  }

  public async onUpload(event: Event = null) {
    const getFromFile = async (): Promise<string> => {
      const files: FileList = event && event.target && event.target['files'] ? event.target['files'] : null;

      if (!files) {
        return;
      }

      return new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(<any>reader.result);
        reader.readAsText(files[0]);
      });
    };

    const getFromClipboard = async (): Promise<string> => {
      if (this.hasClipboardApi) {
        return (<any>window.navigator).clipboard.readText();
      }
    };

    const getVars = (content: string): any => {
      const strip = (content.match(/# variables:.*?[\r\n]/) || [''])[0].replace(/.*?{/, '{');

      if (strip) {
        try {
          const config = JSON.parse(strip);
          return config;
        } catch (error) {
          console.log(error);
        }
      }
    };

    const err = () => alert('Fehler: Es wurden keine Variablen gefunden.');
    const content = event ? await getFromFile() : await getFromClipboard();

    if (content) {
      const vars = getVars(content);
      vars && (this.previousVariables = vars);
      vars && alert('Die Variablen wurden erfolgreich übernommen.');
      vars && this.pack && this.renderPreviousVariables();
      !vars && err();
    } else {
      err();
    }
  }

  /**
   * Loads template pack by url from view
   */
  public async onLoadPack() {
    this.reset();

    try {
      const pack = await this.cfgGenerator.fetch(this.selectedVersion);
      this.originalPack = JSON.parse(JSON.stringify(pack));
      this.render(pack);
      this.previousVariables && this.renderPreviousVariables();
    } catch (error: any) {
      console.error(error);
      alert('Beim Laden des Template Packs ist ein Fehler aufgetreten: ' + error.message);
      return;
    }
  }

  /**
   * Updates mode
   */
  public onUpdateMode() {
    this.render(this.pack);
  }

  /**
   * Generates export document by form variables and rendered template
   */
  public onGenerate() {

    const vars = this.form.value;
    Object.keys(vars).forEach(prop => vars[prop] === undefined && (vars[prop] = ""));

    vars['CHANNEL'] = this.cfgGenerator.getChannel();
    vars['VERSION'] = this.pack.template.version;
    vars['VARIABLES'] = JSON.stringify(vars);

    const content = this.cfgGenerator.render(vars, this.pack.template.content);

    if (this.clipboardMode) {
      this.clipboard.copy(content);
      alert('Die Configuration wurde erfolgreich in die Zwischenablage kopiert.');
      return;
    }
    const dataUrl = this.cfgGenerator.toDataUrl(content, this.pack.template.mimeType);
    const link = document.createElement('a');
    link.download = this.pack.template.name;
    link.href = dataUrl;
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  }

  /**
   * Shows license info
   *
   * @param {string} varName
   */
  public onShowLicenseInfo(varName: string) {
    const keyPair = new KeyPair(null, PUBLIC_KEY);
    const value = this.form.get(varName).value;
    const decoded = JSON.stringify(this.licenseDecoder.decode(value, keyPair), null, 2);
    const html = decoded
      .replace(/[\u00A0-\u9999<>&](?!#)/gim, i => '&#' + i.charCodeAt(0) + ';')
      .replace(/\n/g, '<br>')
      .replace(/ /g, '&nbsp;');

    this.toast && this.toast.info(html, null, {timeOut: 10000, enableHtml: true, closeButton: false});
  }

  /**
   * Renders form by given pack
   *
   * @param pack
   */
  private render(pack: Pack) {
    const formGroup: any = {};
    const fb = this.formBuilder;

    if (!this.selectedMode && pack.template.modes && pack.template.defaultMode) {
      this.selectedMode = pack.template.defaultMode;
    }

    const previousValues = (this.pack && this.form) ? this.form.value : {};

    for (const fieldName of Object.keys(pack.variables)) {
      const validators = [];
      const packVariable: Pack.Variable = pack.variables[fieldName];
      let defaultValue = previousValues[fieldName] || packVariable.default || null;
      const isPreviousValue = !!previousValues[fieldName];

      const isHidden = packVariable.modes && pack.template.modes && this.selectedMode && packVariable.modes.indexOf(this.selectedMode) === -1;
      packVariable.visible = !isHidden;

      if (packVariable.generatePassword && !previousValues[fieldName]) {
        defaultValue = this.pwGenerator.generate(packVariable.generatePassword).replace(/\$/g, '#');
      }

      if (packVariable.regex) {
        const regExp = this.getRegExp(packVariable.regex);
        validators.push(configGeneratorValidators.regexMatchesValidator(fieldName, regExp));
      }

      if (packVariable.isLicense) {
        validators.push(configGeneratorValidators.validLicenseValidator(fieldName));
      }

      formGroup[fieldName] = fb.control(defaultValue, validators);

      if (isPreviousValue) {
        formGroup[fieldName].markAsTouched();
        formGroup[fieldName].markAsDirty();
      }
    }

    this.form = fb.group(formGroup);
    this.varNames = Object.keys(pack.variables);
    this.pack = pack;

    this.form.valueChanges.subscribe(() => {
      this.updateModify();
      this.updateDefaultValues();
    });

    this.form.updateValueAndValidity();
  }

  /**
   * Renders previous values
   */
  private renderPreviousVariables() {
    Object.keys(this.previousVariables).forEach(fieldName => {
      const formControl = this.form.get(fieldName);
      if (formControl) {
        formControl.setValue(this.previousVariables[fieldName], {emitEvent: false});
        formControl.markAsDirty();
        formControl.markAsTouched();
      }
    });

    this.form.updateValueAndValidity();
  }

  /**
   * Updates pack variable properties by modify property
   */
  private updateModify() {
    for (const fieldName of this.varNames) {
      const packVariable: Pack.Variable = this.pack.variables[fieldName];
      const values = this.form.value;
      const formControl = this.form.get(fieldName);

      if (packVariable.modify && Array.isArray(packVariable.modify) && formControl.pristine) {
        for (const modify of packVariable.modify) {
          if (!values[modify.property]) {
            continue;
          }
          const value = values[modify.property];
          const regExp = this.getRegExp(modify.matches);
          const then = modify.then || {};

          if (this.matches(regExp, value)) {
            Object.keys(modify.then || {}).forEach(key => packVariable[key] = then[key]);
          } else {
            Object.keys(modify.then || {}).forEach(key => packVariable[key] = this.originalPack.variables[fieldName][key]);
          }

          formControl.markAsPristine();
        }
      }
    }
  }

  /**
   * Updates default values containing placeholders for variables
   */
  private updateDefaultValues() {
    for (const fieldName of this.varNames) {
      const packVariable: Pack.Variable = this.pack.variables[fieldName];
      const values = this.form.value;
      const formControl = this.form.get(fieldName);

      if (formControl.pristine && !packVariable.generatePassword) {
        const templateVariables = (`${packVariable.default}`
          .match(/\$\{.*?\}/g) || [])
          .map(tVar => tVar.replace(/[${}]/g, ''));

        let defaultValue = packVariable.default;

        templateVariables.forEach(tVar => {
          if (tVar in values) {
            defaultValue = defaultValue.replace(new RegExp(`\\$\\{${tVar}\\}`, 'g'), values[tVar]);
          }
        });

        formControl.setValue(defaultValue, {emitEvent: false});
        formControl.markAsPristine();
      }
    }
  }

  /**
   * Returns regular expressions by given regex string(s)
   *
   * @param regexStrings
   */
  private getRegExp(regexStrings: string | string[]) {
    const regex = [];
    regexStrings = Array.isArray(regexStrings) ? regexStrings : [regexStrings];

    for (const regexString of regexStrings) {
      const matches = regexString.match(/^\/(.*)\/(.*)$/);
      if (matches && matches[1]) {
        regex.push(new RegExp(matches[1], matches[2]));
      }
    }

    return regex;
  }

  /**
   * Returns true when string matches all given regular expressions
   *
   * @param {RegExp[]} regExp
   * @param {string} value
   */
  private matches(regExp: RegExp[], value: string) {
    for (const regEx of regExp) {
      if (!value.match(regEx)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Resets config generator
   */
  private reset() {
    this.varNames = [];
    this.selectedMode = '';
    this.pack = null;
    this.originalPack = null;
    this.form = null;
  }

  private async loadVersions(): Promise<void> {
    this.templateUrl = this.cfgGenerator.getTemplateUrl();

    try {
      this.versions = await this.cfgGenerator.getVersions();
    } catch (error) {
      console.error(error);
      alert('Beim Laden der verfügbaren Versionen ist ein Fehler aufgetreten.')
    }
  }
}
