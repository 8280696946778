import {Component, OnInit, Optional} from '@angular/core';
import {RegistryService} from '@ngmedax/registry';
import {DATE_FORMAT_YMD, DateFormatService, Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_LICENSE_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {ApiService} from '../service/api-service';


// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseComponent extends Translatable {}

/**
 * License component
 */
@Component({
    selector: 'app-license',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.css']
})
@Translatable({scope: TRANSLATION_LICENSE_SCOPE, keys: KEYS})
export class LicenseComponent implements OnInit {
  /**
   * Default locale
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * License object
   * @type {{name: string; validUntil?: string; nonce: string; [key: string]: any;}}
   */
  public license: {
    name: string;
    validUntil?: string;
    nonce: string; [key: string]: any;
    constraint?: {
      numQuest?: number;
      numDevices?: number;
      numSurveysPerMonth?: number;
      domains?: string[];
    }
  };

  /**
   * Formatted license valid until date
   */
  public validUntil: string = null;

  /**
   * Number of submitted surveys for this month
   */
  public numSubmittedSurveys = 0;

  /**
   * Number of questionnaires
   */
  public numQuestionnaires = 0;

  /**
   * Number of devices
   */
  public numDevices = 0;

  /**
   * Constructor
   */
  public constructor(
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private registryService: RegistryService,
    private apiService: ApiService) {
  }

  public ngOnInit() {
    const license = this.registryService.get('license');
    this.license = (typeof license === 'object' && license !== null) ? license : {name: '?'};

    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => this.translateValidUntil());
    this.translateValidUntil();

    this.apiService.getNumDevices().then(count => this.numDevices = count);
    this.apiService.getNumQuestionnaires().then(count => this.numQuestionnaires = count);
    this.apiService.getNumSubmittedSurveysForCurrentMonth().then(count => this.numSubmittedSurveys = count);
  }

  private translateValidUntil() {
    if (!this.translationService || !this.dateFormatService || !this.license || !this.license.validUntil) {
      return;
    }

    const fromLocale = this.locale;
    const toLocale = this.translationService.getLocale();
    const dateFormat = DATE_FORMAT_YMD;
    const dateObj = this.license.validUntil.split('-');
    const date = `${dateObj[2]}.${dateObj[1]}.${dateObj[0]}`;
    this.validUntil = this.dateFormatService.convert({fromLocale, toLocale, dateFormat, date});
  }
}
