export const NAVIGATION = {
  name: 'Patienten',
  icon: 'fa-address-book',
  path: '/module/patient/',
  children: [
    {
      name: 'Liste',
      path: '/module/patient/grid',
      icon: 'fa-table'
    },
    {
      name: 'Neu anlegen',
      path: '/module/patient/crud/new',
      icon: 'fa-star'
    },
  ]
};
