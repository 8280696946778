import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireEditorService} from '../../../services/questionnaire-editor.service';
import {QuestionnaireStateService} from '../../../services/questionnaire-state.service';
import {QuestionnaireVariablesService} from '../../../services/questionnaire-variables.service';
import {VariablesModalComponent} from '../variables/modal/variables-modal.component';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {ScoringModalComponent} from '../scoring/modal/scoring-modal.component';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeMatrixComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-matrix',
  templateUrl: './question-type-matrix.component.html',
  styleUrls: ['./question-type-matrix.component.css', '../../../shared/reusable.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeMatrixComponent implements OnInit {
  @Input() question: Questionnaire.Container;

  public cols: Questionnaire.Container[] = [];
  public rows: Questionnaire.Container[] = [];

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public ngOnInit() {
    // init question elements if not set. this should never happen, but just in case...
    if (!this.question.elements) {
      this.question.elements = [];
    }

    for (const row of this.question.elements) {
      // cols must be the same on all rows (except for the pathHash and parentHash). we just pick the first one
      if (row.elements.length && !this.cols.length) {
        this.cols = this.clone(row.elements);
      }
    }

    // link rows
    this.rows = this.question.elements;
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireEditorService: QuestionnaireEditorService,
    private questionnaireStateService: QuestionnaireStateService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private ref: ChangeDetectorRef,
    private modal: NgbModal) {
  }

  /**
   * Opens modal for questionnaire variables for given container
   *
   * @param {Questionnaire.Container} container
   * @param {boolean} disableInline
   */
  public onVariablesModal(container: Questionnaire.Container, opts = {inline: true, upload: true}) {
    const modalRef = this.modal.open(VariablesModalComponent, {size: 'lg'});
    modalRef.componentInstance.disabledScopes = opts || {inline: true, upload: true, 'pdf-form': true};
    modalRef.componentInstance.container = container;
  }

  /**
   * Opens modal for scoring value
   *
   * @param {number} position
   */
  public onScoringModal(container: Questionnaire.Container) {
    const modalRef = this.modal.open(ScoringModalComponent, {size: 'lg'});
    modalRef.componentInstance.container = container;
  }

  /**
   * Adds a row to this question
   */
  public onAddRow() {
    // generate id for new answer element
    const id = this.questionnaireEditorService.generateUUID();

    // build new row container. link cols to elements
    const row = {
      id,
      path: null,
      pathHash: null,
      parentHash: null,
      title: {'de_DE': ''},
      elements: this.cols
    };

    // push row container to question
    this.rows.push(row);
    this.updateElement();
  }

  /**
   * Deletes a row from this question
   *
   * @param {number} position
   */
  public onDeleteRow(position: number) {
    this.questionnaireEditorService.confirmDelete(() => {
      const row = this.rows[position];
      row && row.pathHash && this.questionnaireVariables.deleteVariableMappings(row.pathHash);
      this.rows.splice(position, 1);

      // update element
      this.updateElement();
    });
  }

  /**
   * Adds a column to this question
   */
  public onAddColumn() {
    // generate id for new answer element
    const id = this.questionnaireEditorService.generateUUID();

    // build new answer
    const column = {
      id,
      path: null,
      pathHash: null,
      parentHash: null,
      title: { 'de_DE': ''}
    };

    // push column element to question
    this.cols.push(column);
    this.updateElement();
  }

  /**
   * Deletes a column from this question
   *
   * @param {number} position
   */
  public onDeleteColumn(position: number) {
    this.questionnaireEditorService.confirmDelete(() => {
      this.cols.splice(position, 1);
      this.updateElement();
    });
  }

  /**
   * Changes the position of a row from current to new
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onRowPositionChange(currentPosition: number, newPosition: number) {
    // change position of element in rows
    this.changePosition(this.rows, currentPosition, newPosition);
  }

  /**
   * Changes the position of a column from current to new
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onColPositionChange(currentPosition: number, newPosition: number) {
    // change position of element in rows
    this.changePosition(this.cols, currentPosition, newPosition);
  }

  /**
   * Rebuilds json and updates element in state service
   */
  public updateElement() {
    // update columns in all rows
    for (const row of this.question.elements) {
      const cols = this.clone(this.cols);
      row.elements = cols;
    }

    // update element (updates path hashes, triggers events)
    this.questionnaireStateService.updateElement(this.question, -1);

    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  /**
   * Changes the position of an element in given elements array by current and new
   *
   * @param {any[]} elements
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  private changePosition(elements: any[], currentPosition: number, newPosition: number) {
    // early bailout when changing positions does not make sense
    if (currentPosition >= (elements.length) || newPosition < 0) {
      return;
    }

    // reorder answers by current and new position
    elements.splice(newPosition, 0, elements.splice(currentPosition, 1)[0]);
    this.updateElement();
  }

  /**
   * Clones the given object
   *
   * @param {any} obj
   * @returns {any}
   */
  private clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}
