/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    RELATED_DATA_NOT_AVAILABLE: 'Verknüpfte Daten nicht vorhanden!',
    ERROR_LOADING_QUESTIONNAIRES: 'Beim Laden der Fragebögen ist ein Fehler aufgetreten!',
    COMPLETED_QUESTIONNAIRES: 'Ausgefüllte Fragebögen',
    STATUS: 'Status',
    SUBMISSION_SUCCESSFUL: 'Abgabe erfolgreich',
    SUBMISSION_PENDING: 'Abgabe läuft',
    SUBMISSION_FAILED: 'Abgabe fehlgeschlagen',
    STATUS_UNKNOWN: 'Status unbekannt',
    QUESTIONNAIRE: 'Fragebogen',
    SEARCH: 'Suche',
    NAME: 'Name',
    ADDRESS: 'Adresse',
    DATE: 'Datum',
    OPTIONS: 'Optionen',
    MR: 'Herr',
    MS: 'Frau',
    DOWNLOAD: 'Download',
    ASSETS: 'Dateien',
    ENTRIES: 'Einträge',
    PATIENT: 'Patient',
    PATIENT_ID: 'Patientennummer'
  },
  TABLE_EXPORT: {
    TABLE_EXPORT: 'Tabellen Export',
    QUESTIONNAIRE: 'Fragebogen',
    REV: 'Rev',
    FROM: 'Von',
    TO: 'Bis',
    EXPORT: 'Export',
    ALL_REVS: 'Alle'
  }
};
