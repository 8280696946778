import {Injectable} from '@angular/core';
import {Token, TokenRule} from '../../../types';

/**
 * Expression statement tokenizer
 */
@Injectable()
export class ExpressionTokenizerService {
  /**
   * Default token rules to parse expression statements into tokens
   * @type TokenRule[]
   */
  private tokenRules: TokenRule[] = [{
    matches: (token) => (token === '('),
    action: (token) => ({type: Token.TYPE.GROUP_OPEN, raw: token})
  }, {
    matches: (token) => (token === ')'),
    action: (token) => ({type: Token.TYPE.GROUP_CLOSE, raw: token})
  }, {
    matches: (token) => (token === 'true' || token === 'false'),
    action: (token) => ({type: Token.TYPE.VALUE, raw: JSON.parse(token)})
  }, {
    matches: (token) => (!!token.match(/^&&$|^\|\|$|^==$|^!=$|^>=$|^<=$|^>$|^<$/)),
    action: (token) => ({type: Token.TYPE.OPERATOR, raw: token})
  }, {
    matches: (token) => (!!token.match(/^[0-9\.]*$/)),
    action: (token) => ({type: Token.TYPE.VALUE, raw: parseFloat(token)})
  }, {
    matches: (token) => (!!token.match(/^[\$a-zA-Z]+[\$\.a-zA-Z0-9_]*$/)),
    action: (token) => ({type: Token.TYPE.VARIABLE, raw: token})
  }, {
    matches: (token) => (!!token.match(/^".*"/)),
    action: (token) => ({type: Token.TYPE.VALUE, raw: token.replace(/"/g, '')})
  }, {
    matches: (token) => (!!token.match(/.*/)),
    action: (token) => {throw new SyntaxError (`Invalid token: ${token}`); }
  }];

  /**
   * Sets custom token rules
   *
   * @param {TokenRule[]} tokenRules
   */
  public setTokenRules(tokenRules: TokenRule[]) {
    this.tokenRules = tokenRules;
  }

  /**
   * Returns currently set token rules
   *
   * @returns: TokenRules[]
   */
  public getTokenRules(): TokenRule[] {
    return this.tokenRules;
  }

  /**
   * Tokenizes given expression statement
   *
   * @param {string} stmt
   * @param {TokenRule[]} rules
   * @returns {Token[]};
   */
  public tokenize(stmt: string, rules: TokenRule[] = this.tokenRules): Token[] {
    const tokens: Token[] = [];
    [{r: /\(/g, v: ' ( '}, {r: /\)/g, v: ' ) '}, {r: / +(?= )/g, v: ''}].forEach(c => stmt = stmt.replace(c.r, c.v));

    stmt.trim().split(' ').forEach((token) =>
      rules.some((rule): boolean => !!rule.matches(token) && !!tokens.push(rule.action(token))));

    return tokens;
  }
}
