import {Component, Input, OnInit, Optional} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireStateService} from '../../../../services/questionnaire-state.service';
import {QuestionnaireVariablesService} from '../../../../services/questionnaire-variables.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../../constants';
import {KEYS} from '../../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface ScoringModalComponent extends Translatable {}

@Component({
  selector: 'app-qa-scoring-modal',
  templateUrl: './scoring-modal.component.html',
  styleUrls: ['./scoring-modal.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ScoringModalComponent implements OnInit {
  @Input() container: Questionnaire.Container;
  public scoringVariableNames: string[] = [];
  public selected: string = null;
  public value: number = 0;

  private previous: {selected: string, value: number} = {selected: null, value: null};
  private readonly questionnaire: Questionnaire;
  private readonly scope = 'scoring';

  constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private questionnaireState: QuestionnaireStateService,
    public activeModal: NgbActiveModal
  ) {
    this.questionnaire = this.questionnaireState.getQuestionnaire();
  }

  public ngOnInit() {
    this.updateSelect();
  }

  public onSave() {
    this.questionnaireVariables.addVariableMapping(this.scope, this.selected, this.container.pathHash, {value: this.value});
  }

  public onUndo() {
    this.value = null;
    this.updateSelect();
  }

  public canSave(): boolean {
    return (!!this.selected && (!!this.value || this.value === 0)) && (this.selected !== this.previous.selected || this.value !== this.previous.value);
  }

  public updateSelect() {
    this.scoringVariableNames = this.questionnaireVariables.getVariableNamesForScope(this.scope);
    this.selected = this.questionnaireVariables.getMappedVariableName(this.scope, this.container.pathHash) || null;
    this.previous.selected = this.selected;
    this.previous.value = 0;

    if (this.selected) {
      const opts = this.questionnaireVariables.getMappedVariableOptions(this.scope, this.container.pathHash, this.selected);
      opts && opts.value && (this.value = opts.value) && (this.previous.value = opts.value);
    }
  }
}
