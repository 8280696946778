<div class="group-qr">
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{_(KEYS.CRUD.INIT_DEVICE)}}</h5>
      <app-init-device-qr-code></app-init-device-qr-code>

      <br>
      <span class="txt-code">{{qrData}}</span>
    </div>
  </div>

  <ng-container *ngIf="iosAppLink">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">iOS app</h5>
        <div class="qr-wrap">
          <i class="fa fa-apple qr-inner"></i>
          <qrcode [qrdata]="iosAppLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>

        <br>
        <a class="btn btn-sm btn-primary" [href]="iosAppLink" target="_blank">Download</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="androidAppLink">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Android app</h5>
        <div class="qr-wrap">
          <i class="fa fa-android qr-inner"></i>
          <qrcode [qrdata]="androidAppLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>

        <br>
        <a class="btn btn-sm btn-primary" [href]="androidAppLink" target="_blank">Download</a>
      </div>
    </div>
  </ng-container>
</div>


