<div class="btn-toolbar d-flex justify-content-between mb-2 qa-item">
  <div class="input-group">
    <select class="custom-select" [(ngModel)]="variableType" (change)="onChangeVariableType()">
      <option value="question">{{_(KEYS.EDITOR.QUESTION)}}</option>
      <option value="variable">{{_(KEYS.EDITOR.VARIABLE)}}</option>
    </select>

    <ng-container *ngIf="variableType === 'question'">
      <app-select2
        cmpCssClasses="with-right select-fix ml-1"
        [options]="select2Opts"
        [(selected)]="selectedQuestion"
        [hideButton]="true"
        [hideLabel]="true"
      >
        <ng-container *ngFor="let question of selectData.questions">
          <option *ngIf="question.title[locale]" value="{{question.pathHash}}">
            {{question.title[locale] | striptags | htmldecode}}
          </option>
        </ng-container>
      </app-select2>

      <div class="input-group-addon qa-fixed-equal">==</div>

      <app-select2
        cmpCssClasses="flat-left flat-right select-fix"
        [options]="select2Opts"
        [(selected)]="expression.variable"
        (selectedChange)="onTriggerChange()"
        [hideButton]="true"
        [hideLabel]="true"
      >
        <ng-container *ngIf="selectedQuestion && selectData.questionToAnswerMap[selectedQuestion]">
          <option value="null">...</option>
          <ng-container *ngFor="let answer of selectData.questionToAnswerMap[selectedQuestion]">
            <option value="container.x{{answer.pathHash}}">
              {{answer.title[locale] | striptags | htmldecode }}
            </option>
          </ng-container>
        </ng-container>
      </app-select2>
    </ng-container>


    <ng-container *ngIf="variableType === 'variable'">
      <app-select2
        cmpCssClasses="with-right select-fix ml-1"
        [options]="select2Opts"
        [(selected)]="expression.variable"
        (selectedChange)="onTriggerChange()"
        [hideButton]="true"
        [hideLabel]="true"
      >
        <option *ngFor="let variable of selectData.variables">
          {{variable}}
        </option>
      </app-select2>

      <app-select2
        cmpCssClasses="with-right flat-left select-fix qa-bg-gray"
        [(selected)]="expression.operator"
        (selectedChange)="onTriggerChange()"
        [hideButton]="true"
        [hideLabel]="true"
      >
        <option value=">">&gt;</option>
        <option value="<">&lt;</option>
        <option value=">=">&gt;=</option>
        <option value="<=">&lt;=</option>
        <option value="==">==</option>
        <option value="!=">!=</option>
      </app-select2>

      <input
        type="number"
        class="num-value"
        [(ngModel)]="expression.value"
        (keydown)="onTriggerChange({timeout: 500})"
        (blur)="onTriggerChange()"
      >
    </ng-container>
  </div>
  <button class="btn btn-danger ml-1" (click)="onClickDelete()">
    <i class="fa fa-trash"></i>
  </button>
</div>
