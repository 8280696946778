export const configKeys = {
  /**
   * Config key for device api uri
   * @type {string}
   */
  DEVICE_URI_CONFIG_KEY: 'apis.device.uri',

  /**
   * Config key for mobile api uri
   * @type {string}
   */
  MOBILE_URI_CONFIG_KEY: 'apis.mobile.uri',
};
