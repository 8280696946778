import {Questionnaire} from '@ngmedax/common-questionnaire-types';


export namespace Question {
  export namespace Structure {
    export const TEXT: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'text',
      'dataType': 'string',
      'displayType': 'text',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'assets': {
        'images': []
      }
    };

    export const NUMERIC: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'numeric',
      'dataType': 'number',
      'displayType': 'text',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'options': {
        'min': 0,
        'max': 300,
        'steps': 1,
        'unit': {'de_DE': ''}
      }
    };

    export const DATE: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'date',
      'dataType': 'string',
      'displayType': 'text',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      }
    };

    export const SINGLE_CHOICE: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'singleChoice',
      'dataType': 'boolean',
      'displayType': 'radio',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'elements': []
    };

    export const MULTIPLE_CHOICE: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'multipleChoice',
      'dataType': 'boolean',
      'displayType': 'checkbox',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'elements': []
    };

    export const MATRIX: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'matrix',
      'dataType': 'boolean',
      'displayType': 'radio',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'options': {
        'multipleAnswers': false,
        'invertAxes': false
      },
      'elements': []
    };

    // virtual type: this is not used when saving the questionnaire
    export const PAGE_BREAK: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'pageBreak',
      'dataType': 'none',
      'displayType': 'none',
      'omitContainer': true,
      'options': { 'mandatory': false, 'active': true},
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      }
    };

    // real type to divide pages. page break will get converted to this, when we save the questionnaire
    // all questions of a page will then be sub elements of a group!
    export const GROUP: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'dataType': 'none',
      'displayType': 'group',
      'omitContainer': true,
      'options': { 'mandatory': false, 'active': true},
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'elements': []
    };

    export const INFO: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'info',
      'dataType': 'none',
      'displayType': 'none',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      }
    };

    export const CANVAS: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'canvas',
      'dataType': 'string',
      'displayType': 'canvas',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'options': {
        'drawColor': '#000000',
        'bgColor': '#ffffff',
        'height': 200
      }
    };

    export const PICTURE: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'picture',
      'dataType': 'string',
      'displayType': 'canvas',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'options': {
        'drawColor': '#000000',
        'bgColor': '#ffffff',
        'height': 200
      }
    };

    export const SIGNATURE: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'signature',
      'dataType': 'string',
      'displayType': 'canvas',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      }
    };

    export const YES_NO: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'singleChoice',
      'extra': 'yesNo',
      'dataType': 'boolean',
      'displayType': 'radio',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'elements': [
        {
          'id': 'ce18f028-8dd3-7ea8-ebcd-2f40fc1974a6',
          'undeletable': true,
          'title': {
            'de_DE': 'Ja'
          }
        }, {
          'id': '8c4e38b0-2f14-e4cf-f97d-4a4832ff8eef',
          'undeletable': true,
          'title': {
            'de_DE': 'Nein'
          }
        }
      ]
    };

    export const YES_NO_MISC: Questionnaire.Container = {
      'id': null,
      'path': null,
      'pathHash': null,
      'parentHash': null,
      'format': 'singleChoice',
      'extra': 'yesNoMisc',
      'dataType': 'boolean',
      'displayType': 'radio',
      'title': {'de_DE': ''},
      'description': {
        'type': 'html',
        'text': {'de_DE': ''}
      },
      'elements': [
        {
          'id': 'ce18f028-8dd3-7ea8-ebcd-2f40fc1974a6',
          'title': {
            'de_DE': 'Ja'
          }
        }, {
          'id': '8c4e38b0-2f14-e4cf-f97d-4a4832ff8eef',
          'title': {
            'de_DE': 'Nein'
          }
        }, {
          'id': '6d93e432-a38f-32ea-b542-2c690145a2c4',
          'undeletable': true,
          'title': {
            'de_DE': 'Sonstiges'
          }
        }, {
          'id': '7d93e432-a38f-32ea-b542-2c690145a2c4',
          'dataType': 'string',
          'displayType': 'text',
          'undeletable': true,
          'title': {
            'de_DE': 'Bitte beschreiben'
          },
          'conditions': {
            'show': '6d93e432-a38f-32ea-b542-2c690145a2c4'
          }
        }
      ]
    };
  }
}
