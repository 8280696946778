import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {KEYS} from '../../../translation-keys';
import {Translatable} from '@ngmedax/translation';
import {Questionnaire, Translation} from '@ngmedax/common-questionnaire-types';
import {TRANSLATION_DEFAULT_SCOPE} from '../../../constants';
import {QuestionnaireSelectService} from '../services/questionnaire-select.service';
import {DragulaService} from 'ng2-dragula';
declare const $: any;


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireSelectComponent extends Translatable {}

@Component({
  selector: 'app-questionnaire-select',
  templateUrl: './questionnaire-select.component.html',
  styleUrls: ['./questionnaire-select.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class QuestionnaireSelectComponent {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Available questionnaires
   * @type {Questionnaire[]} questionnaires
   */
  public questionnaires: Questionnaire[] = [];

  /**
   * Currently selected questionnaire
   * @type {string} choice
   */
  public choice: string = null;

  /**
   * Options for select2
   */
  public options: any = {width: '100%', placeholder: '...',};

  /**
   * Mapping of questionnaire ids to questionnaire titles
   * @type {{[id: string]: Translation}}
   */
  public questionnaireTitles: {[id: string]: Translation} = {};

  /**
   * Currently selected questionnaire ids. You can use this for two-way data binding
   * @type {string[]} selected
   */
  @Input() public selected: string[] = [];

  /**
   * Event emitter for when selected questionnaire ids changed. You can use this for two-way data binding
   * @type {EventEmitter<string[]>} selectedChange
   */
  @Output() public selectedChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  /**
   * Injects dependencies
   * Loads questionnaires
   */
  public constructor(
    private selectService: QuestionnaireSelectService,
    private dragula: DragulaService,
  ) {
    /**
     * Bugfix for dragula: Deletes previous elements bag on progressive page reloads
     */
    for (const bag of ['questionnaire-list-bag']) {
      this.dragula.find(bag) && this.dragula.destroy(bag);
    }

    // only allow dragging via elements that contain the class: qa-handle-control
    this.dragula.createGroup('questionnaire-list-bag', {
      moves: (el, container, handle) => handle.classList.contains('qa-handle-control')
    });

    this.selectService.loadQuestionnaires()
      .then(questionnaires => {
        this.questionnaires = questionnaires;
        this.questionnaires.forEach(q => this.questionnaireTitles[q.id] = q.meta.title);
      })
      .catch(error => {
        console.error(error);
        alert(this._(KEYS.DEFAULT.ERROR_LOADING_QUESTIONNAIRES));
      });
  }

  /**
   * Handler for when questionnaire added to list
   *
   * @param {string} id
   */
  public onAddQuestionnaire(id: string) {
    this.selected.indexOf(id) === -1 && this.selected.push(id);
    this.selectedChange.emit(this.selected);
    this.choice = null;
  }

  /**
   * Handler for when questionnaire position changes in list
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onPositionChange(currentPosition: number, newPosition: number) {
    // early bailout when changing positions does not make sense
    if (currentPosition >= (this.selected.length) || newPosition < 0) {
      return;
    }

    // reorder answers by current and new position
    this.selected.splice(newPosition, 0, this.selected.splice(currentPosition, 1)[0]);
    this.selectedChange.emit(this.selected);
  }

  /**
   * Handler for when questionnaire is deleted from list
   *
   * @param {number} position
   */
  public onDelete(position: number) {
    this.selected.splice(position, 1);
    this.selectedChange.emit(this.selected);
  }
}
