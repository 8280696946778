import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewEncapsulation
} from '@angular/core';

import {Ast} from '@ngmedax/expression-compiler';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {QuestionnaireConditionService} from '../../../../../services/questionnaire-condition.service';
import {Subscription} from 'rxjs';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../../../constants';
import {KEYS} from '../../../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface ConditionsEditorExpressionComponent extends Translatable {}

@Component({
  selector: 'app-qa-conditions-editor-expression',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './conditions-editor-expression.component.html',
  styleUrls: ['./conditions-editor-expression.component.css'],
  encapsulation: ViewEncapsulation.None
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ConditionsEditorExpressionComponent implements OnInit, OnDestroy {
  public variableType: 'question' | 'variable'  = 'variable';
  public selectData: any = {questions: [], variables: []};
  public select2Opts = {width: '200px', placeholder: '...'};
  public selectedQuestion: string;
  public locale = 'de_DE';

  @Input() expression: any = {type: 'expression', variable: null, operator: '>', value: ''};
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription;
  private changeTimeoutRef: any;

  public constructor(
    @Optional() private translationService: TranslationService,
    private conditions: QuestionnaireConditionService,
    private ref: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.selectData = this.conditions.getSelectData();

    this.subscription = this.conditions.onUpdateSelect.subscribe(() => {
      this.selectData = this.conditions.getSelectData();
      this.checkForDelete();
      this.triggerChangeDetection();
    });

    const pathHash = this.getPathHash();

    pathHash && (this.variableType = 'question');
    pathHash && this.selectData.answerToQuestionMap[pathHash]
      && (this.selectedQuestion = this.selectData.answerToQuestionMap[pathHash]);

    this.checkForDelete();
    this.triggerChangeDetection();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onTriggerChange(opts?: {timeout: number}) {
    if (opts && opts.timeout) {
      clearTimeout(this.changeTimeoutRef);
      this.changeTimeoutRef = setTimeout(() => this.conditions.onChange.emit(), opts.timeout);
      return;
    }

    this.conditions.onChange.emit();
  }

  public onClickDelete() {
    this.onDelete.emit();
  }

  public onChangeVariableType() {
    this.variableType === 'question' && (this.expression.operator = '==') && (this.expression.value = true);
    this.triggerChangeDetection();
  }

  /**
   * Triggers change detection
   */
  public triggerChangeDetection() {
    try {
      this.ref.detectChanges();
      this.ref.markForCheck();
    } catch (error) {
      // we can ignore this
    }
  }

  private checkForDelete() {
    const pathHash = this.getPathHash();
    pathHash && !this.selectData.answerToQuestionMap[pathHash] && this.onDelete.emit();
    !pathHash && !!this.expression.variable && this.selectData.variables.indexOf(this.expression.variable) === -1 && this.onDelete.emit();
  }

  private getPathHash(): string {
    return (`${this.expression.variable}`.match(/^container\.x(.*$)/) || [])
      .reduce((all, curr, index) => index > 0 ? all += curr : '', '');
  }
}
