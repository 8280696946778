import {EventEmitter, Injectable} from '@angular/core';
import {Questionnaire, Translation} from '@ngmedax/common-questionnaire-types';
import {QuestionnaireVariablesService} from './questionnaire-variables.service';
import {QuestionnaireStateService} from './questionnaire-state.service';


interface ConditionSelect {
  questions: ConditionSelectQuestion[];
  questionToAnswerMap: {[pathHash: string]: ConditionSelectAnswer};
  answerToQuestionMap: {[pathHash: string]: ConditionSelectAnswer};
  variables: string[];
}

interface ConditionSelectQuestion {
  pathHash: string;
  title: Translation;
}

interface ConditionSelectAnswer {
  pathHash: string;
  title: Translation;
}

@Injectable()
export class QuestionnaireConditionService {
  public onChange: EventEmitter<void> = new EventEmitter<void>();
  public onUpdateSelect: EventEmitter<void> = new EventEmitter<void>();
  private select: ConditionSelect = null;

  /**
   * Hardcoded client (patient) variables
   */
  private clientVariables = [
    'client.age',
    'client.isMale',
    'client.isFemale',
    'client.caseNr',
    'client.customerNr',
    'client.location'
  ];

  private allowedQuestionFormat = [
    Questionnaire.Container.Format.SINGLE_CHOICE,
    Questionnaire.Container.Format.MULTIPLE_CHOICE,
    Questionnaire.Container.Format.MATRIX
  ];

  public constructor(
    private variables: QuestionnaireVariablesService,
    private state: QuestionnaireStateService
  ) {
    this.state.onAddElement.subscribe(() => this.updateSelect());
    this.state.onUpdateElement.subscribe(() => this.updateSelect());
    this.state.onDeleteElement.subscribe(() => this.updateSelect());
  }

  public getSelectData(): ConditionSelect {
    !this.select && this.updateSelect();
    return this.select;
  }

  private updateSelect() {
    const questionnaire = this.state.getQuestionnaire();
    const questions: ConditionSelectQuestion[] = [];
    const questionToAnswerMap: any = {};
    const answerToQuestionMap: any = {};

    for (const question of questionnaire.questions) {
      if (!this.isAllowedQuestionFormat(question)) {
        continue;
      }

      const answers = this.getAnswers(question);

      (answers || []).map(answer => answerToQuestionMap[answer.pathHash] = question.pathHash);

      answers && (questionToAnswerMap[question.pathHash] = answers) && questions.push({
        title: question.title,
        pathHash: question.pathHash,
      });
    }

    const variables = [
      ...this.variables.getVariableNamesForScope('inline').map(value => `inline.${value}`),
      ...this.variables.getVariableNamesForScope('scoring').map(value => `scoring.${value}`),
      ...this.clientVariables
    ];

    this.select = {questions, questionToAnswerMap, answerToQuestionMap, variables};
    this.onUpdateSelect.emit();
  }

  private getAnswers(
    element: Questionnaire.Container
  ):  ConditionSelectAnswer[] {
    const walk = (el: Questionnaire.Container, title: Translation = null, answers: ConditionSelectAnswer[] = []) => {

      // concat titles e.g: el title > sub el title
      !el.omitContainer && title && Object.keys(el.title).forEach(
        locale => title[locale] && (title[locale] = `${title[locale]} > ${el.title[locale]}`));

      // init title if not already done. skip question titles
      !el.omitContainer && !title && !el.format && (title = this.clone(el.title));

      // recursively call for each sub element
      (el.elements || []).forEach((subElement) => walk(subElement, this.clone(title), answers));

      // otherwise add to answers array
      !el.elements && answers.push({title: title, pathHash: el.pathHash});

      return answers;
    };

    return walk(element);
  }

  private isAllowedQuestionFormat(container: Questionnaire.Container) {
    return this.allowedQuestionFormat.indexOf(container.format) !== -1;
  }

  private clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}
