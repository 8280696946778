import {Injectable} from '@angular/core';
import {QuestionnaireEditor} from '../../types';

import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ConfigService} from '@ngmedax/config';
import {ValueService} from '@ngmedax/value';
import {PdfFormService} from '@ngmedax/pdf-form';


@Injectable()
export class QuestionnaireVariablesService {
  /**
   * Currently active questionnaire
   *
   * @type {Questionnaire}
   */
  private questionnaire: Questionnaire;

  /**
   * Scope names for questionnaire variables
   *
   * @type {string[]}
   */
  private scopeNames: string[] = [];

  /**
   * Scope tooltips
   *
   * @type {QuestionnaireEditor.VariableScopes}
   */
  private variableScopes: QuestionnaireEditor.VariableScopes = {};

  /**
   * Injects dependencies
   */
  public constructor(
    private config: ConfigService,
    private value: ValueService,
    private pdfForm: PdfFormService
  ) {
    this.readConfig();
  }

  /**
   * Sets the questionnaire
   *
   * @param {Questionnaire} questionnaire
   */
  public setQuestionnaire(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;
    this.initVariables();
  }

  /**
   * Returns configured questionnaire variable scopes
   */
  public getVariableScopes(): QuestionnaireEditor.VariableScopes {
    return this.variableScopes;
  }

  /**
   * Returns configured variable scope names
   */
  public getVariableScopeNames(): string[] {
    return this.scopeNames;
  }

  /**
   * Returns variable names of given scope
   *
   * @param {string} scope
   */
  public getVariableNamesForScope(scope: string): string[] {
    const variables = this.questionnaire.meta.options.variables;
    return variables[scope] ? Object.keys(variables[scope]) : [];
  }

  /**
   * Returns variables for given scope
   *
   * @param {string} scope
   * @returns {Questionnaire.Options.Variables.Scope}
   */
  public getVariablesForScope(scope: string): Questionnaire.Options.Variables.Scope {
    return this.value.get(this.questionnaire, ['meta', 'options', 'variables', scope]) || {};
  }

  /**
   * Returns pdf form variable scopes
   *
   * @returns {Promise<QuestionnaireEditor.VariableScopes>}
   */
  public async getPdfFormScopes(): Promise<QuestionnaireEditor.VariableScopes> {
    const scopes = {};
    const pdfFormIds = this.value.get(this.questionnaire,['meta','options', 'pdf', 'forms']);

    if (!pdfFormIds || (Array.isArray(pdfFormIds) && !pdfFormIds.length)) {
      return scopes;
    }

    let hr = true;

    for (const pdfFormId of pdfFormIds) {
      const pdfForm = await this.pdfForm.loadPdfForm(pdfFormId);

      const scope = {
        [`pdf-form-${pdfForm.uid}`]: {
          name: pdfForm.name,
          icon: 'fa-file-pdf-o',
          tooltip: `PDF Form: ${pdfForm.name}`,
          allowAccumulation: true,
          allowReturnValue: false,
          skipValidation: true,
          mappings: {},
          hr
        }
      };

      hr = false;

      for (const element of pdfForm.form) {
        if (element.type == 'text') {
          scope[`pdf-form-${pdfForm.uid}`].mappings[element.name] = JSON.stringify([element.name]);
          continue;
        }

        if (element.type == 'choice') {
          for (const option of element.options) {
            scope[`pdf-form-${pdfForm.uid}`].mappings[`${element.name} > ${option}`] = JSON.stringify([element.name, option]);
          }
        }
      }

      Object.assign(scopes, scope);
    }

    return scopes;
  }

  /**
   * Returns flipped questionnaire variables
   *
   * @param variables
   */
  public flipVariables(
    variables: Questionnaire.Options.Variables.Scope
  ): Questionnaire.Options.Variables.Scope.Flipped {
    const flipped: Questionnaire.Options.Variables.Scope.Flipped = {};

    for (const variableName of Object.keys(variables)) {
      const pathHashesObj = variables[variableName];
      for (const pathHash of Object.keys(pathHashesObj)) {
        flipped[pathHash] = variableName;
      }
    }

    return flipped;
  }

  /**
   * Maps variable to a container by its path hash. Also adds variable if not already present.
   *
   * @param scope
   * @param variableName
   * @param pathHash
   * @param options
   */
  public addVariableMapping(scope: string, variableName: string, pathHash: string, options: any) {
    const variables = this.questionnaire.meta.options.variables;
    const pathHashToVarName = this.flipVariables(variables[scope]);
    const previousVariableName = pathHashToVarName[pathHash] || '';

    // deletes previous variable. emulates "rename"
    this.deleteVariableMapping(scope, previousVariableName, pathHash);

    // init path hashes object for variables
    !variables[scope][variableName] && (variables[scope][variableName] = {});

    /**
     * mongoose will not persist empty objects! therefore adding prop "keepMe"
     * see @https://github.com/Automattic/mongoose/issues/848
     */
    (typeof options === 'object') && (options.keepMe = true);

    // add path hash of current question
    variables[scope][variableName][pathHash] = options;
  }

  /**
   * Removes mapping from variable to a container by its path hash. Also removes
   * variable, when no other path hash mapping found for variable and "deleteEmpty"
   * is set to true (default).
   *
   * @param scope
   * @param variableName
   * @param pathHash
   * @param deleteEmpty
   */
  public deleteVariableMapping(scope: string, variableName: string, pathHash: string, deleteEmpty = true) {
    const variables = this.questionnaire.meta.options.variables;

    // deletes mapping from variable to path hash
    variables
      && variables[scope]
      && variables[scope][variableName]
      && variables[scope][variableName][pathHash]
      && delete(variables[scope][variableName][pathHash]);

    // delete variable object if no path hash object remaining
    variables
      && deleteEmpty
      && variables[scope]
      && variables[scope][variableName]
      && !Object.keys(variables[scope][variableName]).length
      && delete(variables[scope][variableName]);
  }

  /**
   * Removes all variable mappings in all scopes for given path hash or container element
   *
   * @param mixed
   */
  public deleteVariableMappings(mixed: string | Questionnaire.Container) {

    if (typeof mixed === 'object' && mixed.id) {
      const walk = (element) => {
        element.pathHash && this.deleteVariableMappings(element.pathHash);

        if (element.elements) {
          for (const subElement of element.elements) {
            walk(subElement);
          }
        }
      };

      const element = <Questionnaire.Container>mixed;
      walk(element);
      return;
    }

    const pathHash = <string>mixed;
    const variablesByScope = this.questionnaire.meta.options.variables;

    for (const scope of Object.keys(variablesByScope)) {
      const variables = variablesByScope[scope];

      for (const variableName of Object.keys(variables)) {
        const pathHashesObject = variables[variableName];
        pathHashesObject && pathHashesObject[pathHash] && this.deleteVariableMapping(scope, variableName, pathHash);
      }
    }
  }

  /**
   * Returns variable name by mapped container, identified by path hash
   *
   * @param scope
   * @param pathHash
   */
  public getMappedVariableName(scope: string, pathHash: string) {
    const variables: Questionnaire.Options.Variables = this.questionnaire.meta.options.variables;

    const pathHashToVarName = this.flipVariables(variables[scope]);
    const variableName = pathHashToVarName[pathHash] || '';
    return variableName;
  }

  /**
   * Returns options for mapped variable of container, identified by path hash
   *
   * @param scope
   * @param pathHash
   * @param variableName
   */
  public getMappedVariableOptions(scope: string, pathHash: string, variableName: string) {
    const variables: Questionnaire.Options.Variables = this.questionnaire.meta.options.variables;

    if (variables[scope] && variables[scope][variableName] && variables[scope][variableName][pathHash]) {
      return variables[scope][variableName][pathHash];
    }

    return {};
  }

  /**
   * Initializes questionnaire variables on given questionnaire
   */
  private initVariables() {
    !this.questionnaire.meta.options && (this.questionnaire.meta.options = {});
    !this.questionnaire.meta.options.variables && (this.questionnaire.meta.options.variables = {});

    const variables = this.questionnaire.meta.options.variables;

    for (const scope of this.scopeNames) {
      !variables[scope] && (variables[scope] = {});

      // converts from old variable format to new one. this should barely happen
      for (const variable of Object.keys(variables[scope])) {
        if (typeof variables[scope][variable] !== 'object') {
          const key = <any>variables[scope][variable];
          variables[scope][variable] = {[key]: {}};
        }
      }
    }
  }

  /**
   * Reads in variable scopes from config
   */
  private readConfig() {
    if (this.scopeNames.length) {
      return;
    }

    const variableScopes: QuestionnaireEditor.VariableScopes
      = this.config.get('questionnaire-editor.variable.scopes');

    if (!variableScopes) {
      return;
    }

    this.variableScopes = variableScopes;

    for (const scope of Object.keys(variableScopes)) {
      const scopeObj = variableScopes[scope];

      if (!scopeObj.name || !scopeObj.tooltip) {
        continue;
      }

      this.scopeNames.push(scope);
    }
  }
}
