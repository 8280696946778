import {Injectable} from '@angular/core';
import {Questionnaire, Translation} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';


/**
 * Service which packs and unpacks matrix questions
 */
@Injectable()
export class QuestionnaireMatrixPackerService {

  /**
   * Injects dependencies
   *
   * @param {ValueService} value
   */
  public constructor(private value: ValueService) {
  }

  /**
   * Unpacks the questionnaire by widening the matrix questions
   *
   * @param {Questionnaire | Questionnaire.Container} element
   */
  public unpack(element: Questionnaire | Questionnaire.Container) {
    // given element could be a questionnaire
    if ((<Questionnaire>element).questions) {
      const questionnaire = <Questionnaire>element;

      // unpack each question
      for (const container of questionnaire.questions) {
        this.unpack(container);
      }
      return;
    }

    // still here? given element is a container
    const container = <Questionnaire.Container>element;

    // not a matrix question? we can't unpack it!
    if (container.format !== Questionnaire.Container.Format.MATRIX) {
      // but it could contain sub elements
      if (container.elements) {
        // lets try to find and unpack matrix questions in sub elements
        for (const subContainer of container.elements) {
          this.unpack(subContainer);
        }
      }
      return;
    }

    // ok, so this is a matrix question. title must be an array (contains "packed" rows)
    const firstTitle = this.value.get(container, ['elements', 0, 'title']);
    const hasPackedRows = !!firstTitle && this.value.isArray(firstTitle);

    if (!hasPackedRows) {
      return;
    }

    // shouldn't be empty! this is were we expect to find the columns
    if (!this.value.isArray(container.elements[0].elements)) {
      container.elements[0].elements = [];
    }

    const rows = this.value.clone(container.elements[0].title);
    const cols = this.value.clone(container.elements[0].elements);

    // we need this id to be able to repack it later
    container.packedId = container.elements[0].id;

    // we have the rows and cols, so lets now overwrite the elements
    container.elements = [];

    // add cols to each row and add rows to elements
    for (const row of rows) {
      const title = this.value.clone(row);

      delete(title.id);
      delete(title.path);
      delete(title.pathHash);
      delete(title.parentHash);

      const rowContainer: Questionnaire.Container = {
        id: (<Translation>row).id,
        title: title,
        elements: []
      };

      for (const col of cols) {
        const colContainer = this.value.clone(col);

        // hashes will be wrong after unpacking, so we remove them now
        delete(colContainer.path);
        delete(colContainer.pathHash);
        delete(colContainer.parentHash);

        rowContainer.elements.push(colContainer);
      }

      container.elements.push(rowContainer);
    }
  }
}
