/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  GRID: {
    DEVICES: "Geräte",
    DEVICE_NAME: "Gerätename",
    DEVICE_ID: "Geräte-ID",
    PATIENT: "Patient",
    OPTIONS: "Optionen",
    DELETE_DEVICE: "Gerät löschen",
    ENTRIES: "Einträge",
    QUESTION_DELETE_DEVICE: "Möchten Sie das Gerät wirklich löschen?",
    SUCCESSFULLY_DELETED_DEVICE: "Das Gerät wurde erfolgreich gelöscht.",
    ERROR_DELETING_DEVICE: "Beim Löschen des Geräts ist ein Fehler aufgetreten.",
    ERROR_LOADING_DEVICE_LIST: "Beim Laden der Geräteliste ist ein Fehler aufgetreten."
  },
  CRUD: {
    INIT_DEVICE: "Gerät initialisieren"
  }
};
