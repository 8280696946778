import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {DeviceService} from '../services/device.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Device} from '../../../types';

// hack to inject decorator declarations. must occur before class declaration!
export interface DeviceGridComponent extends Translatable {}

@Component({
  selector: 'app-device-grid',
  templateUrl: './device-grid.component.html',
  styleUrls: ['./device-grid.component.css']
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class DeviceGridComponent implements OnInit {
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public devices: Device[] = [];

  /**
   * Locale for questionnaires. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Injects dependencies
   */
  constructor(
    @Optional() private translationService: TranslationService,
    private layoutService: LayoutService,
    private deviceService: DeviceService,
    private ref: ChangeDetectorRef
  ) {
  }

  /**
   * Loads devices
   */
  public ngOnInit() {
    this.loadDeviceList();
  }

  /**
   * Event handler for when paging changes. Triggers the change detection
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  /**
   * Checks if the given position of a patient should be displayed according to the currently selected page
   *
   * @param pos
   * @returns {boolean}
   */
  public isOnCurrentPage(pos) {
    const displayPerPage = this.displayPerPage;
    const maxPos = (displayPerPage * this.gridPageNumber) - 1;
    const minPos = (maxPos - displayPerPage) + 1;
    return pos >= minPos && pos <= maxPos;
  }

  /**
   * Removes device from db
   *
   * @param {Device} device
   */
  public async removeDevice(device: Device) {
    const sure = await confirm(this._(KEYS.GRID.QUESTION_DELETE_DEVICE));

    if (sure) {
      this.deviceService.removeDevice(device).then(() => {
        this.devices.splice(this.devices.indexOf(device), 1);
        alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_DEVICE));
      }).catch((err) => {
        alert(this._(KEYS.GRID.ERROR_DELETING_DEVICE));
        console.error(err);
      });
    }
  }

  /**
   * Loads and shows device list
   */
  private loadDeviceList() {
    this.layoutService.showPreloader();

    this.deviceService.getDevices().then((devices) => {
      this.devices = <any>devices;
      this.layoutService.hidePreloader();
    }).catch((err) => {
      alert(this._(KEYS.GRID.ERROR_LOADING_DEVICE_LIST));
      this.layoutService.hidePreloader();
      console.error(err);
    });
  }
}
