import {Injectable} from '@angular/core';
import * as striptags from 'striptags';
import * as htmlEntities from 'html-entities';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

import {SubmissionService} from './submission.service';
import {QuestionnairePathHashService} from './questionnaire/path-hash.service';
import {QuestionnaireAttributeService} from './questionnaire/attributes.service';
import {QuestionnaireMatrixPackerService} from './questionnaire/matrix-packer.service';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';


interface TableHeader {
  mapping: {
    [pathHash: string]: {pathHash: string, title: string}
  };
  pre?: string[];
  post?: string[];
  titles: {pathHash: string, title: string}[];
}

interface TableAnswers {
  pre?: string[];
  post?: string[];
  [headerPathHash: string]: any[];
}

interface Answers {
  [pathHash: string]: any;
}

interface ExportOptions {
  submissionIds: string[];
  workBook?: XLSX.WorkBook;
  skipWrite?: boolean;
  locale?: string;
  rev?: string | number;
}

/**
 * Service to export xlsx document of submissions via browser
 */
@Injectable()
export class TableExportService {
  /**
   * HTML entities decoder
   */
  private entities: {decode(toDecode: string): string};

  /**
   * Injects dependencies and inits html entities decoder
   */
  public constructor(
    private value: ValueService,
    private submission: SubmissionService,
    private pathHash: QuestionnairePathHashService,
    private attribute: QuestionnaireAttributeService,
    private matrixPacker: QuestionnaireMatrixPackerService
  ) {
    this.entities = new (<any>htmlEntities).AllHtmlEntities();
  }

  /**
   * Supported formats for questions
   */
  private readonly supportedFormats = [
    Questionnaire.Container.Format.TEXT,
    Questionnaire.Container.Format.DATE,
    Questionnaire.Container.Format.NUMERIC,
    Questionnaire.Container.Format.SINGLE_CHOICE,
    Questionnaire.Container.Format.MULTIPLE_CHOICE,
    Questionnaire.Container.Format.MATRIX,
  ];

  /**
   * Builds and export xlsx document by given submission id's
   *
   * @param opts
   */
  public async export(opts: ExportOptions): Promise<any> {
    let header: TableHeader;
    let questionnaireTitle: string;
    let revision = <number>opts.rev || null;
    const rows: TableAnswers[] = [];

    typeof opts != 'object' && (opts = {submissionIds: []});
    !opts.submissionIds && (opts.submissionIds = []);
    !opts.locale && (opts.locale = 'de_DE');


    for (const submissionId of opts.submissionIds) {
      const submission = await this.submission.getSubmission(submissionId);
      const questionnaire = submission.questionnaire;
      const patient = submission.client;
      const answers = submission.answers;
      const scoring = this.value.get(submission, ['variables', 'scoring']) || null;

      if (!questionnaire) {
        console.error('found no questionnaire for submission with id:', submission.id);
        continue;
      }

      if (!patient) {
        console.error('found no patient for submission with id:', submission.id);
        continue;
      }

      if (!answers) {
        console.error('found no answers for submission with id:', submission.id);
        continue;
      }

      this.matrixPacker.unpack(questionnaire);
      const pathHashMap = this.pathHash.addPathHashes(questionnaire);
      const questionPathHashes = this.attribute.getQuestionPathHashes(questionnaire);

      !revision && (revision = questionnaire.revision || 0);
      !questionnaireTitle && (questionnaireTitle = this.sanitizeSheetName(questionnaire.meta.title[opts.locale] || ''));
      !header && (header = this.getHeader(pathHashMap, questionPathHashes, opts.locale));
      header.pre = [
        'Id                    ',
        'Patientennummer',
        'Name                ',
        'Geburtsdatum',
        'Standort    ',
        'Status',
        'Ausfülldatum'
      ];

      header.post = [];

      const tableAnswers = this.getAnswers(header, answers, opts.locale, pathHashMap);

      tableAnswers.pre = [
        `${patient.uid}`,
        `${patient.customerNr}`,
        `${patient.firstName} ${patient.lastName}`,
        moment(patient.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        `${patient.location}`,
        `${patient.status}`,
        moment(new Date(submission.receivedDate)).format('DD.MM.YYYY'),
      ];

      tableAnswers.post = [];
      scoring && Object.keys(scoring) && Object.keys(scoring)
        .forEach(key => header.post.push(`scoring.${key}`) && tableAnswers.post.push(scoring[key]));

      rows.push(tableAnswers);
    }

    const data: any = [];
    const titles = [...header.pre, ...header.titles.map((current) => current.title), ...header.post];
    const workSheetColSizes = titles.map(title => ({wch: title.length}));

    data.push(titles);

    for (const answers of rows) {
      const row = header.titles.map((meta) => (answers[meta.pathHash] ? answers[meta.pathHash].join('; ') : ''));
      data.push([...answers.pre, ...row, ...answers.post]);
    }

    const filename = this.getExportFilename(questionnaireTitle, revision);
    const workSheetName = this.getWorksheetName((opts.rev ? `rev#${opts.rev}, `  : '') + questionnaireTitle);
    console.log('xls worksheet name:', workSheetName);

    const workBook = opts.workBook || XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(data);

    workSheet['!cols'] = workSheetColSizes;

    XLSX.utils.book_append_sheet(workBook, workSheet, workSheetName);
    !opts.skipWrite && XLSX.writeFile(workBook, filename);
    return workBook;
  }

  /**
   * Returns header object for given path hash map and question path hashes
   *
   * @param pathHashMap
   * @param questionPathHashes
   * @param locale
   *
   * @returns {TableHeader}
   */
  private getHeader(
    pathHashMap: Questionnaire.Accumulated.QuestionnairePathHashMap,
    questionPathHashes: Questionnaire.Accumulated.QuestionnaireQuestionPathHashMap,
    locale: string
  ): TableHeader {
    const header: any = {mapping: {}, titles: []};

    for (const questionPathHash of Object.keys(questionPathHashes)) {
      const question = <Questionnaire.Container>pathHashMap[questionPathHash];

      // skip unsupported
      if (!this.isSupportedQuestion(question)) {
        continue;
      }

      // special logic for matrix questions
      if (this.isMatrixQuestion(question)) {
        const title = this.sanitize(question.title[locale] || '');

        if (!title) {
          continue;
        }

        for (const column of question.elements) {
          const subTitle = this.sanitize(column.title[locale] || '');
          const subPathHash = column.pathHash;

          if (!subTitle || !subPathHash) {
            continue;
          }

          const columnTitle = `${title} ${subTitle}`;

          const entry = {pathHash: subPathHash, title: columnTitle};
          header.mapping[subPathHash] = entry;
          header.titles.push(entry);
        }

        continue;
      }

      // still here? default logic!
      const title = this.sanitize(question.title[locale]);
      const unit = question.options && question.options.unit && question.options.unit[locale] ?
        ` (${question.options.unit[locale]})` : '';

      if (!title) {
        continue;
      }

      const entry = {pathHash: questionPathHash, title: title + unit};
      header.mapping[questionPathHash] = entry;
      header.titles.push(entry);
    }

    return header;
  }

  /**
   * Returns table answers by given submission answers
   *
   * @param header
   * @param answers
   * @param locale
   * @param pathHashMap
   */
  private getAnswers(
    header: TableHeader,
    answers: Answers,
    locale: string,
    pathHashMap: Questionnaire.Accumulated.QuestionnairePathHashMap
  ): TableAnswers {
    const tableAnswers = {};

    for (const answerPathHash of Object.keys(answers)) {
      const headerPathHash = this.findHeaderPathHash(header, answerPathHash, pathHashMap);

      if (this.isDeprecatedAnswerFormat(answers[answerPathHash], answers)) {
        continue;
      }

      if (headerPathHash) {
        if (answers[answerPathHash] === false) {
          continue;
        }

        const answer = this.getAnswer(answerPathHash, answers, locale, pathHashMap);
        !tableAnswers[headerPathHash] && (tableAnswers[headerPathHash] = []);
        tableAnswers[headerPathHash].push(answer);
      }
    }

    return tableAnswers;
  }

  /**
   * Find matching path hash in header by given answer path hash.
   *
   * @param header
   * @param answerPathHash
   * @param pathHashMap
   */
  private findHeaderPathHash(
    header: TableHeader,
    answerPathHash: string,
    pathHashMap: Questionnaire.Accumulated.QuestionnairePathHashMap
  ): string {
    let headerPathHash = '';

    while (!headerPathHash) {
      headerPathHash = (header.mapping[answerPathHash]) ? answerPathHash : '';
      if (!headerPathHash) {
        answerPathHash = (pathHashMap[answerPathHash]) ? pathHashMap[answerPathHash].parentHash : '';

        if (!answerPathHash) {
          break;
        }
      }
    }

    return headerPathHash;
  }

  /**
   * Returns text answer for given params
   *
   * @param answerPathHash
   * @param answers
   * @param locale
   * @param pathHashMap
   */
  private getAnswer(
    answerPathHash: string,
    answers: Answers,
    locale: string,
    pathHashMap: Questionnaire.Accumulated.QuestionnairePathHashMap
  ) {
    const isElementSelectedAsAnswer = (answers[answerPathHash] === true);
    const hasTextAnswer = isElementSelectedAsAnswer && pathHashMap[answerPathHash]
      && pathHashMap[answerPathHash].title && pathHashMap[answerPathHash].title[locale];

    let answer = hasTextAnswer ? pathHashMap[answerPathHash].title[locale] : '';
    answer = (isElementSelectedAsAnswer) ? answer : answers[answerPathHash];
    return this.convertDate(answer);
  }

  /**
   * Returns export file name by given questionnaire title and revision
   *
   * @param questionnaireTitle
   * @param revision
   */
  private getExportFilename(questionnaireTitle: string, revision: number): string {
    const timestamp = Date.now();
    const title = questionnaireTitle
      .replace(/([^a-z0-9 ]+)/gi, '')
      .replace(/ /gi, '_')
      .toLowerCase();

    return `${title}_rev#${revision}_ts#${timestamp}.xlsx`;
  }

  /**
   * Detects and converts date value to german date format.
   * Otherwise just returns unaltered value.
   *
   * e.g:
   * 2018-12-01 = 01.12.2018
   * 12-2018 = 12.2018
   *
   * @param value
   */
  private convertDate(value: any): any {
    const isString = typeof value === 'string';
    const isFullDate = isString && value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
    const isMonthDate = isString && value.match(/^[0-9]{4}-[0-9]{2}$/);

    (isString && (isFullDate || isMonthDate)) && (value = value.split('-').reverse().join('.'));

    return value;
  }

  /**
   * Returns sanitized worksheet name. Shortens name when bigger
   * then 31 chars, as this is the max allowed chars allowed by spec.
   *
   * @param questionnaireTitle
   */
  private getWorksheetName(questionnaireTitle: string): string {
    return questionnaireTitle.length > 31 ?
      questionnaireTitle.substr(0, 27) + '...' : questionnaireTitle;
  }

  /**
   * Removes html and decodes html entities
   *
   * @param value
   */
  private sanitize(value: string): string {
    return this.entities.decode(striptags(value));
  }

  /**
   * Returns sanitized sheet name
   *
   * @param sheetName
   */
  private sanitizeSheetName(sheetName: string): string {
    return sheetName.replace(/[\:/\\\?\*\[\]]/g, '');
  }

  /**
   * Returns true if given container is a supported question
   *
   * @param container
   */
  private isSupportedQuestion(container: Questionnaire.Container): boolean {
    return (this.supportedFormats.indexOf(container.format) !== -1);
  }

  /**
   * Returns true if given container is a matrix question
   *
   * @param container
   */
  private isMatrixQuestion(container: Questionnaire.Container): boolean {
    return container.format === Questionnaire.Container.Format.MATRIX;
  }

  /**
   * Returns true if answer is of deprecated answer format
   *
   * The old mobile "app" handled single choice differently from multiple choice. Instead of setting answer
   * options to true|false, it used the container path hash as value for selected and deleted the
   * value for unselected answer options. Also it used the question path hash as answer key.
   * E.g: {'pathHashOfQuestion': 'pathHashOfAnswer'} instead of {'pathHashOfAnswerOption': true}
   *
   * @param answer
   * @param answers
   */
  private isDeprecatedAnswerFormat(answer: any, answers: Answers): boolean {
    return typeof answer === 'string' && typeof answers[answer] !== 'undefined';
  }
}
