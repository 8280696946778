<div class="modal-header">
  <h4 class="modal-title">{{_(KEYS.EDITOR.SCORING)}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="container">
    <div class="btn-toolbar">
      <div class="input-group" padding>
        <span class="input-group-addon">{{_(KEYS.EDITOR.SCORING_VARIABLE)}}</span>
        <select class="score-var" [(ngModel)]="selected">
          <option value="null">...</option>
          <option *ngFor="let scoringVarName of scoringVariableNames" [value]="scoringVarName">{{scoringVarName}}</option>
        </select>
      </div>

      <div class="input-group ml-2" padding>
        <span class="input-group-addon">
          {{_(KEYS.EDITOR.SCORING_VALUE_FOR_THIS_ANSWER)}}
        </span>
        <input class="score-value" type="number" [(ngModel)]="value" [disabled]="selected ? null : true">
      </div>

      <div class="input-group ml-1" padding>
        <button class="btn btn-success ml-1" (click)="onSave()" [disabled]="canSave() ? null : true">
          <i class="fa fa-save"></i>
        </button>
        <button class="btn btn-primary ml-1" (click)="onUndo()">
          <i class="fa fa-undo"></i>
        </button>
        <button class="btn btn-danger ml-1">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
    {{_(KEYS.EDITOR.CLOSE)}}
  </button>
</div>
