/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {
    WARNING_SURVEY_LIMIT_ALMOST_REACHED: 'Achtung: Das Limit von [[numSurveys]] Abgaben ist diesen Monat fast aufgebraucht. Es können noch [[left]] Befragungen abgegeben werden.',
    WARNING_SURVEY_LIMIT_REACHED: 'Achtung: In diesem Monat können keine weiteren Befragungen abgegeben werden, da das Limit von [[numSurveys]] erreicht wurde.'
  },
  GRID: {
    DELETE_PATIENT_QUESTION: 'Den folgende Patienten wirklich löschen?',
    ERROR_LOADING_PATIENT: 'Beim Laden der Patienten ist ein Fehler aufgetreten!',
    SUCESSFULLY_DELETED_PATIENT: 'Der Patient wurde erfolgreich gelöscht.',
    ERROR_DELETING_PATIENT: 'Beim Löschen des Patienten ist ein Fehler aufgetreten.',
    ID: 'Id',
    NAME: 'Name',
    LOCATION: 'Standort',
    CREATED_AT: 'Erstellt am',
    OPTIONS: 'Optionen',
    CHANGE: 'Ändern',
    DELETE: 'Löschen',
    ASSIGN_QUESTIONNAIRES: 'Fragebögen zuweisen',
    ENTRIES: 'Einträge',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    ADDRESS: 'Adresse',
    PATIENTS: 'Patienten',
    SEARCH: 'Suche',
    SALUTATION_MR: 'Herr',
    SALUTATION_MS: 'Frau',
    ANONYMOUS: 'Anonym',
    TYPE: 'Typ',
    TYPE_ALL: 'Alle',
    TYPE_NOT_ANONYMOUS: 'Nicht anonym',
    TYPE_ANONYMOUS: 'Anonym'
  },
  CRUD: {
    ENTER_FIRSTNAME: 'Bitte Vorname eingeben...',
    ENTER_LASTNAME: 'Bitte Nachname eingeben...',
    ENTER_ADDRESS: 'Bitte Adresse eingeben...',
    ENTER_LOCATION: 'Bitte Standort eingeben...',
    ENTER_PATIENT_ID: 'Bitte Patientennummer eingeben...',
    ENTER_CASE_NUMBER: 'Bitte Fallnummer eingeben...',
    ENTER_STATUS: 'Bitte Status eingeben...',
    ASSIGNED_QUESTIONNAIRES: 'Zugeordnete Fragebögen',
    ASSIGN_PATIENT: 'Patient zuweisen',
    ASSIGN: 'Zuweisen',
    FILL_OUT_DIRECTLY: 'Direkt ausfüllen',
    COPY_LINK: 'Link kopieren',
    SEND_LINK: 'Link senden',
    ERROR_LOADING_QUESTIONNAIRE_LIST: 'Beim Laden der Fragebogen-Liste ist ein Fehler aufgetreten',
    FOUND_NO_PATIENT_BY_ID: 'Es wurde kein Patient mit folgender Id gefunden:',
    ERROR_LOADING_PATIENT: 'Beim Laden des Patienten ist ein Fehler aufgetreten',
    SUCCESSFULLY_SAVED_PATIENT: 'Der Patient wurde erfolgreich gespeichert',
    ERROR_SAVING_PATIENT: 'Beim Speichern des Patienten ist ein Fehler aufgetreten',
    GENDER: 'Geschlecht',
    MALE: 'Männlich',
    FEMALE: 'Weiblich',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    BIRTHDATE: 'Geburtsdatum',
    ADDRESS: 'Adresse',
    LOCATION: 'Standort',
    ID: 'Patientennummer',
    CASE_NUMBER: 'Fallnummer',
    STATUS: 'Status',
    SAVE_ASSIGN: 'Speichern / Zuweisen',
    SAVED_QR_CODE_HINT: 'Sie können jetzt den QR-Code scannen um den Patient einem initialisierten Gerät zuzuweisen.',
    CLOSE_WINDOW: 'Fenster schließen',
    ANONYMOUS_ALLOCATION: 'Anonyme Zuweisung',
    PATIENT_ANONYMOUS: 'Patient (Anonym)',
    PATIENT: 'Patient',
    ALLOW_MAIL_OPTION: "E-Mail Option",
    ALLOW_MAIL_TOOLTIP: "Ausfüller kann sich nach der Abgabe die Fragebögen zusenden lassen.",
    SURVEY_LIMIT_INFO: "[[current]]/[[max]] Befragungen abgegeben",
    YES: "Ja",
    NO: "Nein"
  }
};

