import { RouterModule, Routes } from '@angular/router';
import { ImprintComponent } from './components/imprint.component';

const APP_ROUTES: Routes = [
    {
      path: 'module/imprint/imprint',
      component: ImprintComponent,
      canActivate: ['CoreGuard'],
      data: {
        needsLogin: true
      }
    }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
