import {Injectable} from '@angular/core';
import {Ast, Token, TokenRule} from '../../../types';
import {ExpressionTokenizerService} from './expression-tokenizer.service';
import {ExpressionParserService} from './expression-parser.service';

/**
 * Expression statement compiler
 */
@Injectable()
export class ExpressionCompilerService {
  /**
   * Injects dependencies
   */
  public constructor(
    private tokenizer: ExpressionTokenizerService,
    private parser: ExpressionParserService
  ) {
  }

  /**
   * Sets custom token rules
   *
   * @param {TokenRule[]} tokenRules
   */
  public setTokenRules(tokenRules: TokenRule[]) {
    this.tokenizer.setTokenRules(tokenRules);
  }

  /**
   * Returns currently set token rules
   *
   * @returns: TokenRules[]
   */
  public getTokenRules(): TokenRule[] {
    return this.tokenizer.getTokenRules();
  }

  /**
   * Tokenizes given expression statement
   *
   * @param {string} stmt
   * @param {TokenRule[]} rules
   * @returns {Token[]};
   */
  public tokenize(stmt: string, rules: TokenRule[] = null): Token[] {
    return this.tokenizer.tokenize(stmt, rules ? rules : this.tokenizer.getTokenRules());
  }

  /**
   * Parses given expression statement into ast
   *
   * @param {string} stmt
   * @param {TokenRule[]} rules
   * @returns {Ast.Group}
   */
  public parse(stmt: string, rules: TokenRule[] = null): Ast.Group {
    return this.parser.parse(stmt, rules);
  }

  /**
   * Compiles given ast into expression statement
   *
   * @param {Ast.Group} ast
   * @returns {string}
   */
  public compile(ast: Ast.Group) {
    return this.compileGroup(ast);
  }

  /**
   * Compiles ast group into expression statement
   *
   * @param {Ast.Group} group
   * @returns {string}
   */
  private compileGroup(group: Ast.Group) {
    const compiler = (buf: string, element: Ast.Group | Ast.Expression) => {
      buf && (buf += ` ${group.operator} `);
      buf += element.type === Ast.TYPE.GROUP ? this.compileGroup(<Ast.Group>element) : this.compileExpression(<Ast.Expression>element);
      return buf;
    };

    return `(${group.elements.reduce(compiler, '')})`;
  }

  /**
   * Compiles ast expression into expression statement
   * @param {Ast.Expression} expr
   * @returns {string}
   */
  private compileExpression(expr: Ast.Expression) {
    const value = typeof expr.value === 'string' ? `"${expr.value}"` : expr.value;
    return `${expr.variable} ${expr.operator} ${value}`;
  }
}
