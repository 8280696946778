import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router, CanActivate} from '@angular/router';
import {filter} from 'rxjs/operators';


/**
 * Service to set layout specific stuff like:
 * - main menu
 * - user object
 * - page title
 * - page logo
 */
@Injectable()
export class LayoutService {
  /**
   * Emitter for when menu should be displayed
   * @type {EventEmitter<any>}
   */
  private displayMenu: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when menu entries changed
   * @type {EventEmitter<any>}
   */
  private menuChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when user menu changed
   * @type {EventEmitter<any>}
   */
  private userMenuChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when user object changed
   * @type {EventEmitter<any>}
   */
  private userChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when active menu entry changed
   * @type {EventEmitter<any>}
   */
  private activeMenuEntryChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when page logo changed
   * @type {EventEmitter<any>}
   */
  private pageLogoChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when page title changed
   * @type {EventEmitter<any>}
   */
  private pageTitleChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when preloader changed
   * @type {EventEmitter}
   */
  private preloaderChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Emitter for when home component changed
   * @type {EventEmitter}
   */
  private homeComponentChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Menu for sidepanel and breadcrumb
   * @type {Array}
   */
  private menu: any[] = [];

  /**
   * User object to render:
   * - username
   * - profile image
   * - deep link to user management: user crud
   */
  private user: object;

  /**
   * User menu. Used in user box
   * @type {Array}
   */
  private userMenu: any[] = [];

  /**
   * Header page title
   */
  private pageTitle: string;

  /**
   * Uri to page logo. Used by sidepanel
   */
  private pageLogo: string;

  /**
   * Software version
   * @type {string}
   */
  private version = '0.0.0.0';

  /**
   * Guards to run when core guard is executed
   * @type {Array}
   */
  private guards: CanActivate[] = [];

  /**
   * Home component
   * @type {any}
   */
  private homeComponent: any = null;

  /**
   * Injects dependencies and trigger "menu changed" event on navigation
   */
  constructor(
    private titleService: Title,
    private router: Router
  ) {
    // subscribe to navigation end event
    router.events
      .pipe(<any>filter((event:Event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // get main menu
        const menu = this.getMenu();
        // try to get active menu entry by router
        const menuEntry = this.getActiveMenuEntry(menu, router);
        // trigger "menu entry changed" event
        this.activeMenuEntryChanged.emit(menuEntry);
      });
  }

  /**
   * Returns the active menu entry by given menu entries. Will first use exact matching
   * fo find the currently active menu entry by its path. If nothing found, it will try
   * with non exact matching so we get an active menu entry for sub uri's.
   * e.g: entry.path: "/module" is active for "/module/home"
   *
   * @param {any[]} entries
   * @param {Router} router
   * @param {boolean} exact
   * @returns {any}
   */
  private getActiveMenuEntry(entries: any[], router: Router, exact: boolean = true) {
    let foundEntry = null;
    let foundSubEntry = null;

    // iterate all given entries
    for (const entry of entries) {

      // set foundEntry when current path is active
      if (entry.path && (router.isActive(entry.path, exact))) {
        foundEntry = entry;
      }

      // we might have child routes, so lets search there to
      if (entry.children) {
        // set found sub entry when a sub path matches
        foundSubEntry = this.getActiveMenuEntry(entry.children, router);

        // override found entry when sub entry found
        if (foundSubEntry) {
          foundSubEntry.parent = entry;
          foundEntry = foundSubEntry;
        }
      }
    }

    // if we found nothing and we are still in exact search mode
    if (exact === true && !foundEntry) {
      // lets try again but this time with "exact = false" which will also
      // mark partial urls as activated. e.g: "/home/" is active when uri is: "/home/foo"
      foundEntry = this.getActiveMenuEntry(entries, router, false);
    }

    return foundEntry;
  }

  /**
   * Registers a guard with given priority
   * @param guard
   * @param {number} priority
   */
  public registerGuard(guard: CanActivate, priority: number = null) {
    if (priority) {
      // don't override guards
      while (this.guards[priority]) {
        priority++;
      }

      this.guards[priority] = guard;
    } else {
      this.guards.push(guard);
    }
  }

  /**
   * Returns registered guards
   * @returns {any[]}
   */
  public getRegisteredGuards(): any[] {
    return this.guards;
  }

  /**
   * Shows menu
   */
  public showMenu() {
    this.displayMenu.emit();
  }

  /**
   * Returns the menu. You can use this in combination with "setMenu"
   * to manipulate the menu
   * @returns {any[]}
   */
  public getMenu() {
    return this.menu;
  }

  /**
   * Sets the menu and triggers the "menu changed" event emitter
   * @param menu
   */
  public setMenu(menu: any[]) {
    this.menu = menu;
    this.menuChanged.emit(this.menu);
  }

  /**
   * Adds a menu entry and triggers the "menu changed' event emitter
   * @param {any} entry
   */
  public addMenuEntry(entry: any) {
    for (const currentEntry of this.menu) {
      if (currentEntry.path === entry.path && currentEntry.children && entry.children) {
        currentEntry.children.push(...entry.children);
        this.menuChanged.emit(this.menu);
        return;
      }
    }

    this.menu.push(entry);
    this.menuChanged.emit(this.menu);
  }

  /**
   * Returns the user menu
   * @returns {any[]}
   */
  public getUserMenu() {
    return this.userMenu;
  }

  /**
   * Sets the user menu and triggers the "user menu changed" event emitter
   * @param userMenu
   */
  public setUserMenu(userMenu: any[]) {
    this.userMenu = userMenu;
    this.userMenuChanged.emit(this.userMenu);
  }

  /**
   * Sets a user menu entry and triggers the "user menu changed" event emitter
   * @param entry
   */
  public addUserMenuEntry(entry: any) {
    this.userMenu.push(entry);
    this.userMenuChanged.emit(this.userMenu);
  }

  /**
   * Sets the user object which is used in the top panel to render
   * the username and the profile image together with a deep link
   * to the user managment system: user crud
   *
   * e.g:
   * let user = {
   *   username: "john.doe@domain.tld",
   *   image: 'https://assets.domain.tld/users/john.doe.jpg'
   * }
   *
   * @param {Object} user
   */
  public setUser(user: object) {
    this.user = user;
    this.userChanged.emit(user);
  }

  /**
   * Returns the user object
   * @returns {Object}
   */
  public getUser() {
    return this.user;
  }

  /**
   * Sets the header page title
   * @param {string} pageTitle
   */
  public setPageTitle(pageTitle: string) {
    this.pageTitle = pageTitle;
    this.titleService.setTitle(this.pageTitle);
    this.pageTitleChanged.emit(pageTitle);
  }

  /**
   * Returns the page title
   * @returns {string}
   */
  public getPageTitle() {
    return this.pageTitle;
  }

  /**
   * Sets the page logo
   * @param {string} pageLogo
   */
  public setPageLogo(pageLogo: string) {
    this.pageLogo = pageLogo;
    this.pageLogoChanged.emit(pageLogo);
  }

  /**
   * Returns the page logo
   * @returns {string}
   */
  public getPageLogo() {
    return this.pageLogo;
  }

  /**
   * Sets the software version
   * @param {string} version
   */
  public setVersion(version: string) {
    this.version = version;
  }

  /**
   * Returns the software version
   * @returns {string}
   */
  public getVersion() {
    return this.version;
  }

  /**
   * Sets home component
   * @param {any} cmp
   */
  public setHomeComponent(cmp: any) {
    this.homeComponentChanged.emit(cmp);
    this.homeComponent = cmp;
  }

  /**
   * Returns home component
   * @returns {any}
   */
  public getHomeComponent(): any {
    return this.homeComponent;
  }

  /**
   * Shows the preloader
   * @param label
   */
  public showPreloader(label: string = null) {
    this.preloaderChanged.emit({
      show: true,
      label: label
    });
  }

  /**
   * Hides the preloader
   */
  public hidePreloader() {
    this.preloaderChanged.emit({show: false});
  }

  /**
   * Subscribes a given closure as listener for then "display menu" event
   *
   * @param {Function} closure
   * @returns {any}
   */
  public subscribeToDisplayMenu(closure: Function) {
    return this.displayMenu.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for the "menu changed" event
   * @param closure
   * @returns Subscription
   */
  public subscribeToMenuChanged(closure: Function) {
    return this.menuChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for the "user menu changed" event
   * @param closure
   * @returns Subscription
   */
  public subscribeToUserMenuChanged(closure: Function) {
    return this.userMenuChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for the "user changed" event
   * @param closure
   * @returns Subscription
   */
  public subscribeToUserChanged(closure: Function) {
    return this.userChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for "active menu entry changed" event
   * @param {Function} closure
   * @returns Subscription
   */
  public subscribeToActiveMenuEntryChanged(closure: Function) {
    return this.activeMenuEntryChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for "page logo" event
   * @param {Function} closure
   * @returns Subscription
   */
  public subscribeToPageLogoChanged(closure: Function) {
    return this.pageLogoChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for "page title" event
   * @param {Function} closure
   * @returns Subscription
   */
  public subscribeToPageTitleChanged(closure: Function) {
    return this.pageTitleChanged.subscribe(closure);
  }

  /**
   * Subscribes a given closure as a listener for the "preloader changed" event
   * @param {Function} closure
   * @returns {any}
   */
  public subscribeToPreloaderChanged(closure: Function) {
    return this.preloaderChanged.subscribe(closure);
  }

  /**
   * Subscribes given closure as listener for "home component changed" event
   * @param {Function} closure
   * returns {any}
   */
  public subscribeToHomeComponentChanged(closure: Function) {
    return this.homeComponentChanged.subscribe(closure);
  }
}
