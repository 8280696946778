declare const $: any;

/**
 * Froala editor function that prevents multi line text
 *
 * @param e
 * @param editor
 * @param clipboardHtml
 */
export const preventMultilineText = function(e, editor, clipboardHtml) {
  // multi line text pasted? not allowed in title!

  const severalP = clipboardHtml.match(/<p/g);
  const containsBr = clipboardHtml.match(/<br>/);

  // contains <br> tag or several <p> tags?
  if (containsBr || (severalP && severalP.length > 1)) {

    // try to match <body>...</body> only
    const matches = clipboardHtml.match(new RegExp('<body(.*)<\/body', 'gs'));

    // found body and jquery is available?
    if (matches && typeof $ === 'function') {
      // strip tags from html
      clipboardHtml = $(matches[0]).text().trim().replace(/\n/g, ' ');
    } else {
      clipboardHtml = '...';
    }

    return clipboardHtml;
  }
};
