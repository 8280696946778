<div class="qa-table-export">
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-table"></i>
            <h5 class="d-inline">{{_(KEYS.TABLE_EXPORT.TABLE_EXPORT)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isTableSearchCollapsed = !isTableSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isTableSearchCollapsed, 'fa-arrow-up': !isTableSearchCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isTableSearchCollapsed">
        <div class="mt-2 mb-2 qa-grid-table-export">
          <div class="input-group mr-2 questionnaire">
            <app-select2
              label="{{_(KEYS.TABLE_EXPORT.QUESTIONNAIRE)}}"
              [options]="selectOptions"
              [hideLabel]="false"
              [hideButton]="true"
              (selectedChange)="onQuestionnaireSelect($event)"
            >
              <option value="">...</option>
              <option *ngFor="let questionnaire of questionnaireTitles" value="{{questionnaire._id}}">
                {{questionnaire.title[locale]}}
              </option>
            </app-select2>
          </div>
          <div class="input-group mr-2 revision">
            <span class="input-group-addon fix">{{_(KEYS.TABLE_EXPORT.REV)}}:</span>
            <select class="form-control" [(ngModel)]="selectedRevision">
              <option *ngFor="let revision of questionnaireRevisions" value="{{revision.revision}}">
                {{revision.revision}}
              </option>
              <option value="all" *ngIf="questionnaireRevisions && questionnaireRevisions.length">
                {{_(KEYS.TABLE_EXPORT.ALL_REVS)}}
              </option>
            </select>
          </div>
          <div class="input-group mr-2 from-date">
            <span class="input-group-addon">{{_(KEYS.TABLE_EXPORT.FROM)}}:</span>
            <input
              type="text" class="form-control"
              ngbDatepicker #fromDate="ngbDatepicker"
              [(ngModel)]="selectedFromDate"
              (focus)="fromDate.toggle()"
            >
          </div>
          <div class="input-group mr-2 to-date">
            <span class="input-group-addon">{{_(KEYS.TABLE_EXPORT.TO)}}:</span>
            <input
              type="text" class="form-control"
              ngbDatepicker #toDate="ngbDatepicker"
              [minDate]="toDateMin"
              [(ngModel)]="selectedToDate"
              (focus)="toDate.toggle()"
            >
          </div>
          <div class="input-group export-btn">
            <span class="input-group-addon fix">{{exportCount}}</span>
            <button
              class="btn btn-primary"
              (click)="onExport()"
              [attr.disabled]="(!canExport() || exportCount <= 0) ? true : null"
            >
              <i class="fa fa-table"></i>&nbsp;{{_(KEYS.TABLE_EXPORT.EXPORT)}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

