import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD, DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {MediaCenterService} from '@ngmedax/asset';

import {SurveyGridService} from '../services/survey-grid.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Survey} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {ConfigService} from '@ngmedax/config';


// hack to inject decorator declarations. must occur before class declaration!
export interface SurveyGridComponent extends Translatable {}

@Component({
  selector: 'app-survey-grid',
  templateUrl: './survey-grid.component.html',
  styleUrls: ['./survey-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class SurveyGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public surveys: Survey[] = [];
  public filter: {patient?: string, status?: string} = {patient: '', status: ''};
  public total = 0;

  /**
   * Locale for surveys. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * Assets base uri
   */
  private assetsBaseUri = '';

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads surveys
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadSurveys();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    @Optional() private mediaCenterService: MediaCenterService,
    private layoutService: LayoutService,
    private surveyGridService: SurveyGridService,
    private ref: ChangeDetectorRef,
    private configService: ConfigService
  ) {
    this.assetsBaseUri = this.configService.get('apis.submission.assets.cdn');
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads surveys
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadSurveys();
  }

  /**
   * Event handler for when filter changes. Loads surveys
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadSurveys(), 1000);
  }

  /**
   * Downloads export file by given file name
   *
   * @param {string} exportFileName
   */
  public onDownload(exportFileName: string) {
    this.assetsBaseUri && window.open(`${this.assetsBaseUri}/${exportFileName}`, '_blank');
  }

  /**
   * Opens given link
   *
   * @param {string} link
   */
  public onOpenLink(link: string) {
    link && window.open(link, '_blank');
  }

  /**
   * Opens media center modal for submission assets
   *
   * @param {{bucketId: string}} submission
   */
  public onOpenMediaCenterModal(submission: {bucketId?: string}) {
    this.mediaCenterService && this.mediaCenterService.openModal(
      {bucketId: submission.bucketId, disableUpload: true, enableZipDownload: true});
  }


  /**
   * Returns surveys
   *
   * @returns {Array}
   */
  public getSurveys(): Survey[] {
    return this.surveys;
  }

  /**
   * Converts mysql date to local ymd date
   *
   * @param {string} date
   * @returns {string}
   */
  public fromMySQLDate(date): string {
    return this.dateFormatService && date ? this.dateFormatService.fromMySqlDate(date, this.getDateFormat(DATE_FORMAT_YMD)) : date;
  }

  /**
   * Returns true if submission has valid exports
   */
  public hasValidExports(submission: any) {
    const exports = submission.exports;
    const isArray = Array.isArray(exports);
    const hasElements = isArray && exports.length > 0;
    const hasObjects = hasElements && typeof exports[0] === 'object';
    const hasExpectedFormat = hasObjects && !!exports[0].type && !!exports[0].fileName;

    return hasExpectedFormat;
  }

  private loadSurveys() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.surveyGridService.loadSurveys(filter, opts)
      .then((result) => {
        this.surveys = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_SURVEYS));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    const like = (value) => ({ '$regex': value, '$options' : 'i' });
    const equal = (value) => ({ '$eq': value});

    this.filter.status && (filter.status = equal(this.filter.status));

    if (this.filter.patient) {
      const likeValue = like(this.filter.patient);
      filter['$or'] = ['firstName', 'lastName', 'customerNr', 'birthDate'].map(key => ({[`client.${key}`]: likeValue}));
    }

    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }
}
