<ng-template #jumpToElement>
  <app-qa-question-index
    [hideLabel]="true"
    [truncateAfter]="20"
    [questionnaire]="questionnaire"
    (done)="jumpToQuestionPopover.close()"
  >
  </app-qa-question-index>
</ng-template>

<div class="qa-editor" #qaEditor>
  <app-qa-io-overlay
    [visible]="ioOverlayVisible"
    [questionnaire]="questionnaire"
    [pathHashMap]="pathHashMap"
    (onHide)="ioOverlayVisible = false"
    (onImportQuestionnaire)="questionnaire = $event"
  >
  </app-qa-io-overlay>

  <h2 class="mb-4">
    {{_(KEYS.EDITOR.QUESTIONNAIRE_EDITOR)}}
  </h2>

  <div class="qa-meta qa-padding qa-border-bottom qa-bg-gray" #qaMeta>
    <input
      class="qa-title form-control"
      placeholder="{{_(KEYS.EDITOR.ENTER_TITLE)}}"
      [(ngModel)]="questionnaire.meta.title[locale]">

    <div class="qa-type input-group">
      <span class="input-group-addon">{{_(KEYS.EDITOR.QUESTIONNAIRE_TYPE)}}</span>
      <select class="form-control qa-custom-select" [(ngModel)]="questionnaire.meta.type">
        <option value="default">{{_(KEYS.EDITOR.QUESTIONNAIRE)}}</option>
        <option value="infosheet">{{_(KEYS.EDITOR.INFORMATION_SHEET)}}</option>
      </select>
    </div>

    <div class="qa-status input-group">
      <span class="input-group-addon">{{_(KEYS.EDITOR.STATUS)}}</span>
      <select class="form-control qa-custom-select" [(ngModel)]="questionnaire.status">
        <option value="draft">{{_(KEYS.EDITOR.DRAFT)}}</option>
        <option value="active">{{_(KEYS.EDITOR.APPROVED)}}</option>
      </select>
      <span class="input-group-addon">
        {{_(KEYS.EDITOR.REV)}}
        {{questionnaire.revision <= 0 ? 1 : questionnaire.revision}}
      </span>
    </div>


    <div class="qa-filtergroup input-group">
      <app-select2
        cmpCssClasses="qa-filtergroup"
        label="{{_(KEYS.EDITOR.FILTER_GROUPS)}}"
        [hideButton]="true"
        [multiple]="true"
        [options]="filtergroupSelectOpts"
        [(selected)]="questionnaire.meta.tags"
      >
        <option *ngFor="let filtergroup of filtergroups" value="{{filtergroup.id}}">
          {{filtergroup.name[locale]}}
        </option>
      </app-select2>
    </div>

    <div class="qa-spacing"></div>

    <div class="qa-buttons">
      <button
        class="btn btn-primary qa-upper"
        [attr.disabled]="saveInProgress? true: null"
        (click)="onSaveQuestionnaire()"
      >
        <i class="fa fa-save"></i>
        {{_(KEYS.EDITOR.SAVE)}}
      </button>
    </div>
    <div class="qa-display-settings">
      <div class="d-inline-block">
        <div ngbDropdown>
          <button class="btn btn-primary qa-upper mr-1" ngbDropdownToggle>
            {{_(KEYS.EDITOR.SHOW)}}
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onFoldQuestions()">
              {{_(KEYS.EDITOR.NO_CONTENT)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionContents()">
              {{_(KEYS.EDITOR.ALL_CONTENT)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionImages()">
              {{_(KEYS.EDITOR.ALL_IMAGES)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionVideos()">
              {{_(KEYS.EDITOR.ALL_VIDEOS)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionAudio()">
              {{_(KEYS.EDITOR.ALL_AUDIO)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionDescriptions()">
              {{_(KEYS.EDITOR.ALL_DESCRIPTIONS)}}
            </button>
            <button class="dropdown-item qa-upper qa-cursor-pointer" (click)="tabs.onExpandQuestionConditions()">
              {{_(KEYS.EDITOR.ALL_DEPENDENCIES)}}
            </button>
          </div>
        </div>
      </div>
      <button class="btn btn-primary mr-1" (click)="tabs.onExpandQuestions()" ngbTooltip="{{_(KEYS.EDITOR.EXPAND_ELEMENTS)}}">
        <i class="fa fa-expand"></i>
      </button>
      <button class="btn btn-primary mr-1" (click)="tabs.onFoldQuestions()" ngbTooltip="{{_(KEYS.EDITOR.FOLD_ELEMENTS)}}">
        <i class="fa fa-compress"></i>
      </button>
      <span ngbTooltip="{{_(KEYS.EDITOR.JUMP_TO_ELEMENT)}}">
        <button
          class="btn btn-primary mr-1"
          [ngbPopover]="jumpToElement"
          placement="top-left"
          popoverTitle="{{_(KEYS.EDITOR.JUMP_TO_ELEMENT)}}"
          triggers="manual" container="body"
          #jumpToQuestionPopover="ngbPopover"
          (click)="onJumpToQuestion(jumpToQuestionPopover)"
        >
          <i class="fa fa-forward"></i>
        </button>
      </span>
      <app-qa-preview [questionnaire]="questionnaire"></app-qa-preview>
      <button class="btn btn-secondary ml-1" (click)="ioOverlayVisible = true">
        <i class="fa fa-retweet"></i>
      </button>
    </div>
    <div class="qa-bar-info qa-upper">
      <div>
        {{questionnaire.questions.length}} {{_(KEYS.EDITOR.ELEMENTS)}}
      </div>
    </div>
    <div class="qa-bar-control">
      <button
        class="btn btn-primary mr-1"
        ngbTooltip="{{_(KEYS.EDITOR.FULL_SCREEN)}}"
        (click)="domHelper.toggleCls(qaEditor, 'maximized')"
      >
        <i class="fa fa-toggle-maximize"></i>
      </button>
      <button
        class="btn btn-primary mr-1"
        ngbTooltip="{{_(KEYS.EDITOR.OPTIONS)}}"
        (click)="domHelper.toggleCls(qaMeta, 'expanded', [qaMetaTab])"
      >
        <i class="fa fa-toggle-expand"></i>
      </button>
    </div>
  </div>
  <div class="qa-meta-tab mt-3" #qaMetaTab>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-copy"></i> {{_(KEYS.EDITOR.DESCRIPTION)}}</a>
        <ng-template ngbNavContent>
          <div class="mt-3" [froalaEditor]="defaultEditorOptions" [(froalaModel)]="questionnaire.meta.description[locale]"></div>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-image"></i> {{_(KEYS.EDITOR.IMAGE)}}</a>
        <ng-template ngbNavContent>
          <div>
            <ng-container>
              <div class="card qa-card-inline-block mt-3">
                <div class="card-footer">
                  <div class="btn-toolbar justify-content-between">
                    <div class="justify-content-between">
                      <label class="btn btn-primary qa-no-margin mr-1" #qaEditorImageUpload (click)="onOpenMediaCenterModal()">
                        <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_IMAGE)}}
                      </label>
                    </div>
                    <button
                      class="btn btn-danger"
                      (click)="onDeleteImage()"
                      [attr.disabled]="!(questionnaire.meta.asset.image && questionnaire.meta.asset.image.filename[locale])? true: null">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <img
                    class="card-img-top qa-editor-image mb-3"
                    *ngIf="questionnaire.meta.asset.image && questionnaire.meta.asset.image.filename[locale]; else qaEditorImgPlaceholder"
                    [src]="mediaCenter.getAssetUrl(questionnaire.meta.asset.image, locale)"
                    (click)="onOpenMediaCenterModal(questionnaire.meta.asset.image)" />
                  <ng-template #qaEditorImgPlaceholder>
                    <img
                      class="card-img-top qa-editor-image mb-3"
                      src="/assets/images/placeholder.png"
                      (click)="onOpenMediaCenterModal()"/>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-sort-numeric-asc"></i> {{_(KEYS.EDITOR.SCORING)}}</a>
        <ng-template ngbNavContent>
          <app-qa-scoring-variables [questionnaire]="questionnaire"></app-qa-scoring-variables>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-pencil-square-o"></i> {{_(KEYS.EDITOR.FILL_OPTIONS)}}</a>
        <ng-template ngbNavContent>
          <form class="pdf-opts">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="questionnaire.meta.options.fill.forceMandatory"
              >
              <label class="form-check-label">{{_(KEYS.EDITOR.FORCE_MANDATORY)}}</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="questionnaire.meta.options.fill.skipList"
              >
              <label class="form-check-label">{{_(KEYS.EDITOR.SKIP_LIST)}}</label>
            </div>
          </form>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-file-pdf-o"></i> {{_(KEYS.EDITOR.PDF)}}</a>
        <ng-template ngbNavContent>
          <form class="pdf-opts">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="questionnaire.meta.options.pdf.generateInternal"
              >
              <label class="form-check-label">{{_(KEYS.EDITOR.GENERATE_INTERNAL_PDF)}}</label>
            </div>
          </form>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-file-pdf-o"></i> {{_(KEYS.EDITOR.GDT)}}</a>
        <ng-template ngbNavContent>
          <form class="pdf-opts">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="questionnaire.meta.options.gdt.addQuestionnaireTitle"
              >
              <label class="form-check-label">
                {{_(KEYS.EDITOR.ADD_QUESTIONNAIRE_TITLE_TO_GDT)}}
                <button
                  class="btn btn-sm btn-primary label-btn"
                  (click)="onGdtMetaTitleVariablesModal()"
                  [attr.disabled]="!questionnaire.meta.options.gdt.addQuestionnaireTitle ? true : null">
                  <i class="fa fa-code"></i>
                </button>
              </label>
              <br>
              <input
                type="checkbox"
                class="form-check-input"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="questionnaire.meta.options.gdt.addScoring"
                (change)="addDefaultGdtScoringVariables()"
              >
              <label class="form-check-label">{{_(KEYS.EDITOR.ADD_SCORING_TO_GDT)}}</label>
              <ng-container *ngIf="questionnaire.meta.options.gdt.addScoring && hasScoring()">
                <div class="card gdt-scoring-card">
                  <ng-container *ngFor="let scoringVar of getScoringVariables()">
                    <label class="form-check-label">
                      {{scoringVar}}
                      <button
                        class="btn btn-sm btn-primary label-btn"
                        (click)="onGdtMetaScoringVariablesModal(scoringVar)">
                        <i class="fa fa-code"></i>
                      </button>
                    </label>
                  </ng-container>
                </div>

              </ng-container>
            </div>
          </form>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem *ngIf="feature.pdf.forms">
        <a ngbNavLink><i class="fa fa-file-pdf-o"></i> {{_(KEYS.EDITOR.PDF_FORMS)}}</a>
        <ng-template ngbNavContent>
          <form class="pdf-form-opts">
            <app-pdf-form-select
              [selected]="questionnaire.meta.options.pdf.forms"
              (selectedChange)="questionnaire.meta.options.pdf.forms = $event">
            </app-pdf-form-select>
          </form>
          <hr>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink><i class="fa fa-code"></i> Mapping</a>
        <ng-template ngbNavContent>
          <form class="pdf-form-opts">
            <app-qa-map-variables></app-qa-map-variables>
          </form>
          <hr>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  <div class="qa-questions mt-3">
    <app-qa-add-question></app-qa-add-question>

    <ng-container *ngIf="questionnaire">
      <div class="elements" [dragula]='"elements-bag"' [dragulaModel]="questionnaire.questions">
        <ng-container *ngFor="let question of questionnaire.questions;let i = index;">
          <ng-container *ngIf="mappings[question.format]">
            <ng-container
              *ngComponentOutlet="mappings[question.format].main;
              ndcDynamicInputs: {position: i, feature: feature, question: question, mapping: mappings[question.extra? question.format + '#' + question.extra: question.format]};">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div class="mt-3" *ngIf="questionnaire && questionnaire.questions && questionnaire.questions.length > 5">
      <app-qa-add-question></app-qa-add-question>
    </div>

    <div class="qa-buttons mt-3">
      <button
        class="btn btn-primary qa-upper"
        [attr.disabled]="saveInProgress? true: null"
        (click)="onSaveQuestionnaire()"
      >
        <i class="fa fa-save"></i>
        {{_(KEYS.EDITOR.SAVE)}}
      </button>
    </div>
  </div>
</div>
