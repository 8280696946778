<div class="license-crud">
  <h2 class="mb-4">{{_(KEYS.LICENSE.LICENSE)}}</h2>

  <table class="table">
    <tbody>
    <tr>
      <th scope="col"><i class="fa fa-lock"></i></th>
      <th scope="col">{{_(KEYS.LICENSE.TYPE)}}</th>
      <td>&nbsp;</td>
      <td>myMedax 4</td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-user"></i></th>
      <th scope="row">{{_(KEYS.LICENSE.LICENSEE)}}</th>
      <td>&nbsp;</td>
      <td>{{license.name}}</td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-calendar"></i></th>
      <th scope="row">{{_(KEYS.LICENSE.VALID_UNTIL)}}</th>
      <td>&nbsp;</td>
      <td *ngIf="!validUntil">{{_(KEYS.LICENSE.UNLIMITED)}}</td>
      <td *ngIf="validUntil">{{validUntil}}</td>
    </tr>
    </tbody>
  </table>

  <br>
  <h5 class="mb-4">{{_(KEYS.LICENSE.LIMITS)}}</h5>
  <table class="table">
    <tbody>
    <tr>
      <th scope="col"><i class="fa fa-paper-plane"></i></th>
      <th scope="col">{{_(KEYS.LICENSE.NUM_SURVEYS_PER_MONTH)}}</th>
      <td>&nbsp;</td>
      <td *ngIf="license.constraint && license.constraint.numSurveysPerMonth; else ulSurveys">
        {{numSubmittedSurveys}}/{{license.constraint.numSurveysPerMonth}}
      </td>
      <ng-template #ulSurveys>
        <td>{{_(KEYS.LICENSE.UNLIMITED)}}</td>
      </ng-template>
    </tr>
    <tr>
      <th scope="col"><i class="fa fa-id-card"></i></th>
      <th scope="col">{{_(KEYS.LICENSE.NUM_QUESTIONNAIRES)}}</th>
      <td>&nbsp;</td>
      <td *ngIf="license.constraint && license.constraint.numQuest; else ulQuest">
        {{numQuestionnaires}}/{{license.constraint.numQuest}}
      </td>
      <ng-template #ulQuest>
        <td>{{_(KEYS.LICENSE.UNLIMITED)}}</td>
      </ng-template>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-tablet"></i></th>
      <th scope="row">{{_(KEYS.LICENSE.NUM_DEVICES)}}</th>
      <td>&nbsp;</td>
      <td *ngIf="license.constraint && license.constraint.numDevices; else ulDevice">
        {{numDevices}}/{{license.constraint.numDevices}}
      </td>
      <ng-template #ulDevice>
        <td>{{_(KEYS.LICENSE.UNLIMITED)}}</td>
      </ng-template>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-globe"></i></th>
      <th scope="row">Domains</th>
      <td>&nbsp;</td>
      <td *ngIf="license.constraint && license.constraint.domains; else allDomains">
        <div *ngFor="let domain of (license.constraint.domains || [])">{{domain}}</div>
      </td>
      <ng-template #allDomains>
        <td>{{_(KEYS.LICENSE.UNLIMITED)}}</td>
      </ng-template>
    </tr>
    </tbody>
  </table>
</div>
