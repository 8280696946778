export const KEYS = {
  LICENSE: {
    LICENSE: 'Lizenz',
    TYPE: 'Lizenztyp',
    LICENSEE: 'Lizenznehmer',
    VALID_UNTIL: 'Gültig bis',
    UNLIMITED: 'unbegrenzt',
    LIMITS: 'Einschränkungen',
    NUM_QUESTIONNAIRES: 'Anzahl Fragebögen',
    NUM_SURVEYS_PER_MONTH: 'Abgaben pro Monat',
    NUM_DEVICES: 'Anzahl Geräte'
  }
};
