<div class="impress">
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <h2>{{_(KEYS.IMPRINT.IMPRINT)}}</h2>
      </div>
      <div class="card-text">
        <p><strong>SUXEDO GmbH &amp; Co. KG</strong></p>
        <p>Sulzbachtalstraße 128<br>66125 Saarbrücken</p>
        <p>Telefon 0681 / 952 630 20<br>Telefax 0681 / 952 630 39</p>
        <p>Geschäftsführer: Dirk Becker, Franz-Werner Britz<br>Amtsgericht Saarbrücken HRA 11981</p>
        <p>UST-ID Nr.: DE305573826</p>
      </div>
    </div>
  </div>
</div>


