<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.COMPLETED_QUESTIONNAIRES)}}</h2>

  <div class="card mb-2">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-questionnaire">
            <span class="input-group-addon">{{_(KEYS.GRID.QUESTIONNAIRE)}}:</span>
            <input class="form-control" [(ngModel)]="filter.questionnaireTitle"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-name">
            <span class="input-group-addon">{{_(KEYS.GRID.PATIENT)}}:</span>
            <input class="form-control" [(ngModel)]="filter.patient"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-questionnaire-submission-table-export></app-questionnaire-submission-table-export>

  <div class="card">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.COMPLETED_QUESTIONNAIRES)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>

      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">Id</th>
            <th>{{_(KEYS.GRID.QUESTIONNAIRE)}}</th>
            <th>{{_(KEYS.GRID.PATIENT)}}</th>
            <th>{{_(KEYS.GRID.STATUS)}}</th>
            <th>{{_(KEYS.GRID.DATE)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let submission of getSubmissions(); let pos = index;">

            <ng-template #notFound>
              <div ngbTooltip="{{_(KEYS.GRID.RELATED_DATA_NOT_AVAILABLE)}}">
                <i class="fa fa-times"></i>
              </div>
            </ng-template>

            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{submission.id}}">{{submission.id | truncate: 5}}</span>
              </td>
              <td>
                <span ngbTooltip="{{submission.questionnaire.meta.title[locale]}}" *ngIf="submission.questionnaire; else notFound">
                  {{submission.questionnaire.meta.title[locale] | truncate: 25}}
                </span>
              </td>
              <td>
                {{_(KEYS.GRID.PATIENT_ID)}} - {{submission?.client?.customerNr}} *{{fromMySQLDate(submission.client.birthDate)}} <br>
                <ng-container *ngIf="submission.client; else notFound">
                  <span *ngIf="submission.client.gender === 'male'">
                    {{_(KEYS.GRID.MR)}}
                  </span>
                  <span *ngIf="submission.client.gender === 'female'">
                    {{_(KEYS.GRID.MS)}}
                  </span>
                  {{submission.client.firstName}} {{submission.client.lastName}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!submission.status || submission.status === 'unknown'">
                  <i class="fa fa-question text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_UNKNOWN)}}"></i>
                </ng-container>
                <ng-container *ngIf="!submission.status || submission.status === 'successful'">
                  <i class="fa fa-check text-success" ngbTooltip="{{_(KEYS.GRID.SUBMISSION_SUCCESSFUL)}}"></i>
                </ng-container>
                <ng-container *ngIf="submission.status && submission.status === 'failed'">
                  <i class="fa fa-times text-danger" ngbTooltip="{{_(KEYS.GRID.SUBMISSION_FAILED)}}: {{submission.requestId}} >> {{submission.error && submission.error.message ? submission.error.message : ''}}"></i>
                </ng-container>
                <ng-container *ngIf="submission.status && submission.status === 'pending'">
                  <i class="fa fa-refresh text-primary" ngbTooltip="{{_(KEYS.GRID.SUBMISSION_PENDING)}}"></i>
                </ng-container>
              </td>
              <td>
                {{submission.receivedDate | datex:dateFormat}}
              </td>
              <td>
                <div ngbDropdown class="d-inline-block" [attr.disabled]="!submission.exports ? true : null">
                  <button class="btn btn-primary d-inline-block mr-1" ngbDropdownToggle>
                    {{_(KEYS.GRID.DOWNLOAD)}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button
                      class="dropdown-item"
                      *ngIf="submission.bucketId && hasAssetSupport"
                      (click)="onOpenMediaCenterModal(submission)"
                    >
                      <i class="fa fa-window-maximize"></i> {{_(KEYS.GRID.ASSETS)}}
                    </button>

                    <button
                      class="dropdown-item"
                      (click)="onOpenLink(submission.uploadLinks['ownCloud'])"
                      *ngIf="submission.uploadLinks && submission.uploadLinks['ownCloud']"
                    >
                      <i class="fa fa-cloud"></i> ownCloud
                    </button>
                    <hr *ngIf="(submission.bucketId && hasAssetSupport) || (submission.uploadLinks && submission.uploadLinks['ownCloud'])"/>

                    <ng-container *ngIf="hasValidExports(submission)">
                      <ng-container *ngFor="let export of submission.exports">
                        <button class="dropdown-item" (click)="onDownload(export.fileName)" *ngIf="export.type !== 'EXTERN'">
                          <i class="fa fa-download"></i>&nbsp;&nbsp;{{export?.type}}
                        </button>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>

        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>

      </div>
    </div>
  </div>
</div>
