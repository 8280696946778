import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';

import {PatientService} from '../services/patient.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Patient} from '../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface PatientGridComponent extends Translatable {}

@Component({
  selector: 'app-patient-grid',
  templateUrl: './patient-grid.component.html',
  styleUrls: ['./patient-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class PatientGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public patients: Patient[] = [];
  public filter: Patient.Filter = {};
  public total = 0;

  /**
   * Locale for patients. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * date format to use in grid
   * @type {string}
   */
  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads patients and initializes search filter
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.filter = {anonymous: 1};
    this.loadPatients();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private layoutService: LayoutService,
    private patientService: PatientService,
    private ref: ChangeDetectorRef) {
  }

  public async onDeletePatient(patient: Patient) {
    const sure = await confirm(this._(KEYS.GRID.DELETE_PATIENT_QUESTION) + `: ${patient.firstName} ${patient.lastName}`);

    if (sure) {
      this.patientService
        .deletePatient(patient)
        .then(() => {
          this.patients.splice(this.patients.indexOf(patient), 1);
          alert(this._(KEYS.GRID.SUCESSFULLY_DELETED_PATIENT));
        })
        .catch(error => {
          console.error(error);
          alert(this._(KEYS.GRID.ERROR_DELETING_PATIENT));
        });
    }
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads patient
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadPatients();
  }

  /**
   * Event handler for when filter changes. Loads patients
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadPatients(), 1000);
  }

  /**
   * Returns patients which are allowed by currently set filter
   *
   * @returns {Array}
   */
  public getPatients(): Patient[] {
    return this.patients;
  }

  private loadPatients() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.patientService.loadPatients(filter, opts)
      .then((result) => {
        this.patients = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_PATIENT));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    Object.keys(this.filter).forEach((key) => filter[key] = { '$regex' : this.filter[key], '$options' : 'i' });

    delete(filter.anonymous);
    this.filter.anonymous == 1 && (filter.anonymous = {'$ne': 1});
    this.filter.anonymous == 2 && (filter.anonymous = 1);
    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }
}
