import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {configKeys} from '../../../config-keys';


@Injectable()
export class QuestionnaireSelectService {
  /**
   * Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private http: HttpClient
  ) {
  }

  /**
   * Method to load questionnaires.
   *
   * @returns {Promise<Questionnaire[]>}
   */
  public loadQuestionnaires(): Promise<Questionnaire[]> {
    const basePath = this.getQuestionnaireApiUrl() + '?order=meta.title.de_DE&active=true';

    return new Promise((resolve, reject) => {
      this.http.get(basePath, {headers: this.getAuthHeaders()})
        .subscribe((response: any) => {
            const questionnaires = response.questionnaire || response.rows || response;
            resolve(questionnaires);
          },
          error => {
            reject(error);
          });
    });
  }

  /**
   * Returns api url for questionnaire
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getQuestionnaireApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   *
   * @param {string} configKey
   * @returns {any}
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
